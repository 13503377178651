import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { format } from "node:path/win32";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, IconButton, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InputAdornment from "@mui/material/InputAdornment";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import CustomModal from "../../Shared/ModalCustom/CustomModal";
import { EditCoordModal } from "./EditCoordMap/EditCoordModal";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import { getAllFormat } from "../../../service/FormatService/FormatService";
import {
  deleteWayfindingPhoto,
  getWayfindingById,
  getWayfindingPhotos,
  updateWayfinding,
} from "../../../service/WayfindingServices/WayfindingService";
import { getAllSector } from "../../../service/SectorService/SectorService";
import { getAllDelegations } from "../../../service/DelegationServices/DelegationServices";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import { getDBPromotions } from "../../../service/PromotionServices/PromotionServices";

import "./EditWayfinding.css";

const validationSchema = yup.object({
  format: yup.object(),
  territorial: yup.object(),
  promotion: yup.object(),
  circlet: yup.number(),
  sector: yup.object(),
  photos: yup.mixed(),
  //do we want to validate dates?
  // photos: yup.mixed().test('fileSize', "La imagen es demasiado grande", value => value.size <= FILE_SIZE)
  //  .test('fileType', "Formato inválido", value => SUPPORTED_FORMATS.includes(value.type) )
});

export const EditWayfinding = () => {
  const params: any = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wayfinding, isFetchIngWayfinding] = useFetch(
    getWayfindingById,
    params.wf_id
  );
  const user = useSelector((state: any) => state.userReducer.user?.user);

  const [promotions, setPromotions] = useState<any[]>([]);
  const [promotionsDWH, setPromotionsDWH] = useState<any[]>([]);
  const [formats, setFormats] = useState<any[]>([]);
  const [delegations, setDelegations] = useState<any[]>([]);
  // const [sectors, setSectors] = useState<any[]>([]);
  const [promoDTSector, setPromoDTSector] = useState<any[]>([]);
  // const [sectores, setSectores] = useState<any[]>([])
  const [wfFormat, setWfFormat] = useState(null);
  const [wfDelegation, setWfDelegation] = useState(null);
  const [wfSector, setWfSector] = useState(null);
  const [wfPromotion, setWfPromotion] = useState(null);

  const norepobj_sectores = (arr: any) => {
    let unique: any[] = [];
    let list = arr.filter((el: any) => {
      let isDup = unique.includes(el.sector);
      if (!isDup) {
        unique.push(el.sector);
        return true;
      }
    });
    return list;
  };

  useEffect(() => {
    if (promotionsDWH) {
      let promo_dt_sector: any[] = [];
      promotionsDWH.map((p: any) =>
        promo_dt_sector.push({
          promo: p.id,
          dt: p.id_delegacion,
          sector: p.sector,
        })
      );
      // setSectores(norepobj_sectores(promo_dt_sector).map((obj:any,index:number) => {
      //   return {id:index,name:obj.sector}
      // }))
      setPromoDTSector(promo_dt_sector);
    }
  }, [promotionsDWH]);

  useEffect(() => {
    if (!isFetchIngWayfinding) {
      let promises: any = [];

      promises.push(
        getAllFormat().then((response: any) => setFormats(response?.data))
      );

      promises.push(
        getAllPromotions().then((response: any) =>
          setPromotionsDWH(response?.data)
        )
      );

      promises.push(
        getDBPromotions() //from DB tables instead from data warehouse
          .then((response: any) => {
            setPromotions(response?.data);
          })
      );

      promises.push(
        getAllDelegations().then((response: any) =>
          setDelegations(response?.data)
        )
      );

      // promises.push(
      //   getAllSector().then((response: any) => setSectors(response?.data))
      // );

      Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => console.error(error));
    }
  }, [params.wf_id, isFetchIngWayfinding]);

  useEffect(() => {
    if (!isLoading) {
      if (wayfinding && formats) {
        setWfFormat(
          formats?.find(
            // @ts-ignore: Unreachable code error
            (format: any) => format.id === wayfinding["id_format"]
          )
        );
      }

      if (wayfinding && delegations) {
        setWfDelegation(
          delegations?.find(
            // @ts-ignore: Unreachable code error
            (delegation: any) =>
              delegation.idDelegacion ===
              wayfinding["id_territorial_delegation"]
          )
        );
      }

      if (wayfinding && promotionsDWH && promotions.length > 1) {
        // @ts-ignore: Unreachable code error
        //find sobre /promotion
        let proyecto_prinex = promotions.find(
          (promotion: any) => promotion.id === wayfinding.id_promotion
        );

        //find sobre /all
        setWfPromotion(
          promotionsDWH?.find(
            (promotion: any) =>
              promotion.proyecto_prinex === proyecto_prinex.name
          )
        );
      }
    }
  }, [formats, delegations, promotionsDWH, promotions, isLoading]);

  return (
    <>
      {isLoading &&
      !wayfinding &&
      !wfDelegation &&
      !wfFormat &&
      // !wfSector &&
      !wfPromotion ? (
        <AedasLoading />
      ) : (
        <>
          <h2 className="page-title">Editar</h2>
          <EditWFForm
            wayfinding={wayfinding}
            wfFormat={wfFormat}
            wfPromotion={wfPromotion}
            wfDelegation={wfDelegation}
            // wfSector={wfSector}
            promotionsDWH={promotionsDWH}
            delegations={delegations}
            formats={formats}
            // sectors={sectors}
            // sectores={sectores}
            promoDTSector={promoDTSector}
            user_id={user.id}
            wf_id={params.wf_id}
          />
        </>
      )}
    </>
  );
};

const EditWFForm = (props: any) => {
  console.log("pp", props);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [newCoords, setNewCoords] = useState<any[]>([]);
  const history = useHistory();
  const [photos, isFetchingPhotos] = useFetch(getWayfindingPhotos, props.wf_id);
  console.log(props, newCoords);

  const {
    wayfinding,
    wfFormat,
    wfPromotion,
    wfDelegation,
    wfSector,
    promotionsDWH,
    delegations,
    formats,
    sectors,
    promoDTSector,
    sectores,
  } = props;
  const [files, setFiles] = useState<any[]>([]);

  const sorterFunction = (a: any, b: any) => {
    if (a.proyecto_prinex < b.proyecto_prinex) return -1;
    if (a.proyecto_prinex > b.proyecto_prinex) return 1;
    return 0;
  };

  const sorterFormats = (a: any, b: any) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  promotionsDWH?.sort(sorterFunction);
  formats?.sort(sorterFormats);

  const formik = useFormik({
    initialValues: {
      latitude: wayfinding?.lat,
      longitude: wayfinding?.long,
      format: wfFormat,
      promotion: wfPromotion,
      circlet: wayfinding?.circlet.toString(),
      territorial: wfDelegation,
      // sector: wfSector,
      price: wayfinding?.monthly_price,
      comments: wayfinding?.comments,
      fromDate: wayfinding["start_contact_date"]
        ? moment(wayfinding["start_contact_date"]).format("yyyy-MM-DD")
        : null,
      untilDate: wayfinding["finish_contact_date"]
        ? moment(wayfinding["finish_contact_date"]).format("yyyy-MM-DD")
        : null,
      photos: [],
    },
    enableReinitialize: true,

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      console.log("data-->", values, filesToSend, "wf-->", wayfinding);
      updateWayfinding(values, props.user_id, filesToSend, props.wf_id).then(
        () => {
          history.push("/mapa/" + values.latitude + "," + values.longitude);
          toast.success("El wayfinding se ha editado correctamente");
        }
      );
    },
  });

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });
    formik.handleChange(event);
    setFiles(fileListBase64);
  };

  if (isFetchingPhotos) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className="form-edit-container"
        onSubmit={formik.handleSubmit}
      >
        <div className="inputs-container">
          <Autocomplete
            id="format"
            fullWidth
            value={formik.values.format}
            onChange={(e, value) => {
              formik.setFieldValue("format", value);
            }}
            options={formats}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Formato"
                error={formik.touched.format && Boolean(formik.errors.format)}
                helperText={formik.touched.format && formik.errors.format}
              />
            )}
          />

          <div className="cood-container">
            <TextField
              id="latitude"
              fullWidth
              label="Latitud"
              variant="filled"
              disabled
              type={"number"}
              value={formik.values.latitude}
              // onChange={formik.handleChange}
              // error={formik.touched.latitude && Boolean(formik.errors.latitude)}
              // helperText={formik.touched.latitude && formik.errors.latitude}
            />

            <TextField
              id="longitude"
              fullWidth
              label="Longitud"
              variant="filled"
              disabled
              type={"number"}
              value={formik.values.longitude}
              // onChange={formik.handleChange}
              // error={formik.touched.longitude && Boolean(formik.errors.longitude)}
              // helperText={formik.touched.longitude && formik.errors.longitude}
            />

            <MyLocationIcon
              className="location-icon"
              onClick={() => setOpenMap(!openMap)}
              style={{ cursor: "pointer" }}
            />
          </div>

          <Autocomplete
            id="promotion"
            fullWidth
            options={promotionsDWH}
            getOptionLabel={(option: any) => option.proyecto_prinex}
            onChange={(e, value) => {
              formik.setFieldValue("promotion", value);
              formik.setFieldValue(
                "territorial",
                delegations.find((d: any) => d.id === value.id_delegacion)
              );
              let relacion = promoDTSector.find(
                (p: any) => p.promo === value.id
              );

              formik.setFieldValue(
                "sector",
                sectores.find((s: any) => relacion.sector === s.name)
              );
            }}
            value={formik.values.promotion}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Promoción"
                error={
                  formik.touched.promotion && Boolean(formik.errors.promotion)
                }
                helperText={formik.touched.promotion && formik.errors.promotion}
              />
            )}
          />
          <Autocomplete
            id="circlet"
            fullWidth
            options={["1", "2", "3"]}
            getOptionLabel={(option: any) => option}
            onChange={(e, value) => {
              formik.setFieldValue("circlet", value);
            }}
            value={formik.values.circlet}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Anillo"
                value={formik.values.circlet}
                error={formik.touched.circlet && Boolean(formik.errors.circlet)}
                helperText={formik.touched.circlet && formik.errors.circlet}
              />
            )}
          />

          <TextField
            id="price"
            fullWidth
            label="Precio"
            variant="outlined"
            value={formik.values.price}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />

          <TextField
            id="fromDate"
            fullWidth
            label="Fecha desde"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type={"date"}
            value={formik.values.fromDate}
            onChange={formik.handleChange}
            error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
            helperText={formik.touched.fromDate && formik.errors.fromDate}
          />

          <TextField
            id="untilDate"
            fullWidth
            label="Fecha hasta"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            type={"date"}
            value={formik.values.untilDate}
            inputProps={{
              min: formik.values.fromDate,
            }}
            onChange={formik.handleChange}
            error={formik.touched.untilDate && Boolean(formik.errors.untilDate)}
            helperText={formik.touched.untilDate && formik.errors.untilDate}
          />
          <TextField
            fullWidth
            id="comments"
            label="Anotaciones"
            // defaultValue={wayfinding ? wayfinding['comments'] : ''}

            variant="outlined"
            value={formik.values.comments}
            onChange={formik.handleChange}
            error={formik.touched.comments && Boolean(formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: "30px",
          }}
        >
          <TextField
            InputLabelProps={{ shrink: true }}
            style={{ width: "100%" }}
            inputProps={{
              multiple: true,
              accept: "image/png, image/jpeg",
            }}
            fullWidth
            id="photos"
            label="Fotos"
            type={"file"}
            // value={formik.values.photos}
            onChange={(event: any) => {
              handleUploadFiles(event.currentTarget.files[0], event);
            }}
            error={formik.touched.photos && Boolean(formik.errors.photos)}
            helperText={formik.touched.photos && formik.errors.photos}
          />

          <div
            style={{
              marginTop: "30px",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              border: "1px solid",
              color: "#80808073",
              gap: "10px",
              borderRadius: "10px",
            }}
          >
            {photos.photos.length > 0
              ? photos?.photos?.map((photo: any) => (
                  <ImgView
                    photo={photo}
                    wfId={wayfinding.id}
                    promoId={wfPromotion?.id}
                  ></ImgView>
                ))
              : "No hay imagenes"}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "400px",
            }}
          >
            <Button
              className="save_button"
              color="primary"
              onClick={() => {
                history.push(
                  "/mapa/" +
                    formik.values.latitude +
                    "," +
                    formik.values.longitude
                );
              }}
            >
              Volver
            </Button>
            <Button className="save_button" color="primary" type="submit">
              Guardar
            </Button>
          </div>
        </div>
      </Box>

      <CustomModal
        size="xl"
        show={openMap}
        onChange={() => {
          setOpenMap(!openMap);
        }}
        // title={`Detalle Incidencia por ${selectedType.name}`}
        title="Editar coordenadas del Wayfinding"
        body={
          <EditCoordModal
            lngLat={[wayfinding?.long, wayfinding?.lat]}
            formik={formik}
            setOpenMap={() => setOpenMap(!openMap)}
            setNewCoords={setNewCoords}
            newCoords={newCoords}
          />
        }
      />
    </>
  );
};

function ImgView(props: any) {
  const [display, setdisplay] = useState(true);
  const handleRemove = () => {
    deleteWayfindingPhoto(
      [props.photo.name],
      props.wfId,
      parseInt(props.promoId)
    )
      .then(() => {
        setdisplay(false);
        toast.success("Se ha borrado correctamente");
      })
      .catch((error) => {
        toast.error("Ha habido un problema, intentelo más tarde");
      });
  };
  return (
    <div
      style={
        display
          ? {
              maxHeight: "150px",
              border: "1px",
              borderColor: "black",
              position: "relative",
              maxWidth: "300px",
            }
          : { display: "none" }
      }
    >
      <IconButton
        style={{
          position: "absolute",
          background: "var(--aedas-red)",
          left: "10px",
          top: "10px",
        }}
        className="remove_img_button"
        onClick={handleRemove}
      >
        <DeleteIcon></DeleteIcon>
      </IconButton>
      <img
        style={{ maxHeight: "150px", objectFit: "contain" }}
        src={"data:image/jpeg;base64," + props.photo.photos}
      ></img>
    </div>
  );
}
