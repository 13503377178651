import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";

import { MapEditCood } from "./MapEditCoord";

export const EditCoordModal = (props: any) => {
  const [longLat, setLongLat] = useState(props.lngLat);

  console.log(props, longLat);
  const [newCoords, setNewCoords] = useState<any[]>([]);

  const handleUpdateCoord = () => {
    console.log("click");
    props.setOpenMap();
    props.formik.setFieldValue("latitude", newCoords[1]);
    props.formik.setFieldValue("longitude", newCoords[0]);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <MapEditCood
        lngLat={props.lngLat}
        setNewCoords={setNewCoords}
        newCoords={newCoords}
        formik={props.formik}
      />
      <Button
        className="save_button"
        color="primary"
        type="submit"
        onClick={handleUpdateCoord}
      >
        Actualizar coordenadas
      </Button>
    </div>
  );
};
