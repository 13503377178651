import axios from "axios";
import { toast } from "react-toastify";

//services to get data from Wayfinding API not from the data warehouse

export const getDBPromotions = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/promotion/",
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getPromotionsByIdUser = (id_user: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/promotion/user/" + id_user,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
