import moment from "moment";
import * as React from "react";
import jwt_decode from "jwt-decode";

export const isLoginJWT = () => {
  let userStr: any = localStorage.getItem("aedas_user");
  if (userStr && userStr !== "null") {
    let user = JSON.parse(userStr);
    let codeUser: any = jwt_decode(user.token);
    let exp: number = codeUser.exp;
    delete codeUser.exp;
    if (
      moment(exp * 1000).isBefore(moment()) ||
      codeUser?.email !== user?.user?.email
    ) {
      return { user: null, isLogin: false };
    } else {
      return { user: user, isLogin: true };
    }
  } else {
    return { user: null, isLogin: false };
  }
};
