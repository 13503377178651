import React, { useState, useCallback, useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";

import Map, { MapRef, Marker, MarkerDragEvent, ViewStateChangeEvent } from 'react-map-gl';


const MapNewWayfinding = (props: any) => {
  const { formik, lngLat } = props;
  const mapRef = useRef<MapRef | null >(null);

  const INITIAL_LONG = -3.7038293713725383;
  const INITIAL_LAT = 40.416904892379016;

  const [viewState, setViewState] = useState({
    longitude: INITIAL_LONG,
    latitude: INITIAL_LAT,
    zoom: 4.8
  });

  const [marker, setMarker] = useState<any>({
    longitude: INITIAL_LONG,
    latitude: INITIAL_LAT,
  });

  useEffect(() => {
    if (( lngLat[1] !== 0 && lngLat[0] !== 0 )) {
      setMarker({
        longitude: lngLat[0],
        latitude: lngLat[1],
      });
      
      mapRef.current?.flyTo({center: [lngLat[0], lngLat[1]], zoom: 14});
    }

  }, [lngLat]);

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });   
  }, []);

  useEffect(() => {
    formik.setFieldValue("latitude", marker.latitude);
    formik.setFieldValue("longitude", marker.longitude);
  }, [marker]);

  return (
    <Map
        {...viewState}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        style={{width: 'calc(100% - 20px)',minHeight: '500px', margin: "10px"}}
        onMove={evt => setViewState(evt.viewState)}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN || ""}
      >
      <Marker 
        longitude={marker.longitude} 
        latitude={marker.latitude} 
        anchor="bottom" 
        draggable={true} 
        color="#092e3b"
        onDrag={onMarkerDrag}
        >
        <img src={"/Assets/img/icons/location_on_black.png"} />
      </Marker>
    </Map>

  );
};

export default MapNewWayfinding;
