import axios from "axios";
import { toast } from "react-toastify";


export const getAllSector = () => {
  const config = {
		headers: {
				Authorization: `Bearer ${
				JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
				}`,
		},
	};
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/sector/",
    config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
  });
};

export const getSectorById = (id: number) => {
	const config = {
		headers: {
				Authorization: `Bearer ${
				JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
				}`,
		},
	};

	return axios
		.get(
				process.env.REACT_APP_API_BACKEND + "/sector/" + id,
				config
		)
		.catch((error) => {
				toast.error("Ha habido un problema, intentelo más tarde");
		});
  };
  