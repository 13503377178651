import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Shared/ModalCustom/CustomModal";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import {
  getIncByWFId,
  getAllIncTypes,
  getIncidences,
  updateIncidence,
} from "../../../service/IncidenceServices/IncidenceService";
import { getWayfindingById } from "../../../service/WayfindingServices/WayfindingService";
import { getAllFormat } from "../../../service/FormatService/FormatService";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import { getDBPromotions } from "../../../service/PromotionServices/PromotionServices";

import { IncidenceDetail } from "../Map/IncidenceDetail/IncidenceDetail";
import { toast } from "react-toastify";

export const IncidenceList = (props: any) => {
  const params: any = useParams();

  const [wayfinding, isFetchIngWayfinding] = useFetch(
    getWayfindingById,
    params.wf_id
  );
  const [formats, isFetchingFormats] = useFetch(getAllFormat);
  const [incidenceTypes, isFetchingIncidenceTypes] = useFetch(getAllIncTypes);
  const [incidences, isFetchingIncidences] = useFetch(
    getIncByWFId,
    params.wf_id
  );
  const [promotionsDWH, isFetchingPromotions] = useFetch(getAllPromotions);
  const [promotions, isFetchingPromos] = useFetch(getDBPromotions);

  const [wfPromotion, setWfPromotion] = useState(null);
  const [wfFormat, setWfFormat] = useState(null);
  const [wfIncidences, setWfIncidences] = useState(null);
  const [openIncidenceDetails, setOpenIncidenceDetails] =
    useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();

  const isLoading = useIsLoading([
    isFetchIngWayfinding,
    isFetchingFormats,
    isFetchingIncidenceTypes,
    isFetchingIncidences,
    isFetchingPromotions,
    isFetchingPromos,
  ]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "comment",
      headerName: "Anotaciones",
      type: "string",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "active",
      headerName: "Activo",
      type: "text",
      renderCell: (params: GridRenderEditCellParams) => {
        return <>{params.value === true ? "Activado" : "Desactivado"}</>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params}></CustomEditComponent>;
      },
      width: 200,
      editable: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      // @ts-ignore: Unreachable code error
      const proyecto_prinex = promotions.find(
        (promotion: any) => promotion.id === wayfinding.id_promotion
      );

      // @ts-ignore: Unreachable code error
      setWfPromotion(
        promotionsDWH?.find(
          (promotion: any) => promotion.proyecto_prinex === proyecto_prinex.name
        )
      );

      setWfIncidences(incidences);

      // @ts-ignore: Unreachable code error
      const format: any = formats?.find(
        (format: any) => format.id === wayfinding.id_format
      );
      setWfFormat(format);
    }
  }, [params.wf_id, isLoading]);

  // const onCellClick = (params: any) => {
  //   setSelectedRow(params.row);
  // };

  const handleSave = (data: any) => {
    data.edited.map((incEdited: any) => {
      updateIncidence(incEdited.id).then(() =>
        toast.success("Incidencia desactivada con éxito")
      );
    });
  };

  const onCellClick = (event: any) => {
    setSelectedRow(event.row);
    // @ts-ignore: Unreachable code error
    const incidenceType = incidenceTypes.find(
      (type: any) => type.id === event.row.id_type
    );
    setSelectedType(incidenceType);

    setOpenIncidenceDetails(!openIncidenceDetails);
  };

  return (
    <>
      {!isLoading && wfPromotion && wfFormat && wfIncidences ? (
        <div>
          <h2 className="page-title">Incidencias</h2>

          <p className="text-center">
            <strong>Promoción: </strong>
            {
              // @ts-ignore: Unreachable code error
              wfPromotion.proyecto_prinex
            }
            <span> - </span>
            <strong>Wayfinding</strong>
            <span>: </span>

            {
              // @ts-ignore: Unreachable code error
              wfFormat.name
            }
          </p>
          <DataGridEditabled
            showHeader={true}
            rows={wfIncidences}
            columns={columns}
            handleSave={handleSave}
            showRemoveIcon={false}
            singleIcon={true}
            exportIcon={true}
            onlyEdit={false}
            onCellClick={(event: any) => onCellClick(event)}

            // addIcon={true}
          ></DataGridEditabled>

          <CustomModal
            size="xl"
            show={openIncidenceDetails}
            onChange={() => {
              setOpenIncidenceDetails(!openIncidenceDetails);
            }}
            // title={`Detalle Incidencia por ${selectedType.name}`}
            title="Detalle Incidencia"
            body={
              <IncidenceDetail incidence={selectedRow} type={selectedType} />
            }
          />
        </div>
      ) : (
        <AedasLoading />
      )}
    </>
  );
};

const CustomEditComponent = (props: any) => {
  const { id, value, field } = props;
  const [active, setActive] = useState(value);

  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    setActive(!active);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <Checkbox checked={active} onChange={handleChange} />;
};
