import React, { FC, useState } from "react";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import FilterForm from "./FilterForm/FilterForm";

import "./MapFilter.css";

export const MapFilter: FC<any> = (props): any => {
  const [showFilter, setShowFilter] = useState(false);

  const handleOpenFilterForm = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="filter">
      <FilterListRoundedIcon
        className="filter-icon"
        onClick={handleOpenFilterForm}
      />

      {showFilter ? (
        <div className="dropdown_filter">
          <FilterForm
            filters={props.filters}
            setFilterOptions={props.setFilterOptions}
            handleFilter={props.handleFilter}
            handleFilterReset={props.handleFilterReset}
            handleOpenFilterForm={handleOpenFilterForm}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
