import React, { useState, useEffect } from "react";

export const useFetch = (service: any, params: any = "") => {
  const [data, setData] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(true);
  const updateData = () => {
    setIsFetching(true);
  };
  useEffect(() => {
    if (isFetching) {
      service(params)
        .then((response: any) => setData(response.data))
        .finally(() => setIsFetching(false));
    }
  }, [isFetching]);

  return [data, isFetching, updateData];
};

export const useIsLoading = (isLoadingArray: any) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let isLoadingValue = false;
    isLoadingArray.map((value: boolean) => {
      if (value) {
        isLoadingValue = true;
      }
    });
    if (isLoadingValue) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingArray]);
  return isLoading;
};
