import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const CircletOptions = (props: any) => {
  useEffect(() => {
    setChecked([true, true, true]);
  }, [props.flagReset]);

  const [checked, setChecked] = useState([true, true, true]);

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checkUpdate = [
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ];
    setChecked(checkUpdate);
    props.handleShowCirclets(checkUpdate);
  };

  const handleChange1km = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checkUpdate = [event.target.checked, checked[1], checked[2]];
    setChecked(checkUpdate);
    props.handleShowCirclets(checkUpdate);
  };

  const handleChange3km = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checkUpdate = [checked[0], event.target.checked, checked[2]];
    setChecked(checkUpdate);
    props.handleShowCirclets(checkUpdate);
  };

  const handleChange7km = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checkUpdate = [checked[0], checked[1], event.target.checked];
    setChecked(checkUpdate);
    props.handleShowCirclets(checkUpdate);
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        className="circlet-option hidden"
        id="control1km"
        control={<Checkbox checked={checked[0]} onChange={handleChange1km} />}
        label="Anillo 1km"
      />
      <FormControlLabel
        className="circlet-option hidden"
        id="control3km"
        control={<Checkbox checked={checked[1]} onChange={handleChange3km} />}
        label="Anillo 3km"
      />
      <FormControlLabel
        className="circlet-option hidden"
        id="control7km"
        control={<Checkbox checked={checked[2]} onChange={handleChange7km} />}
        label="Anillo 7km"
      />
    </Box>
  );

  const handleDisplayedCirclets = () => {
    console.log("anillos");
  };

  const handleShowOptions = () => {
    const optionItems = document.querySelectorAll(".circlet-option");
    const icon = document.querySelector(".circlet-ref-icon");

    optionItems.forEach((item: any) => item.classList.toggle("hidden"));
    icon?.classList.toggle("rotate");

    // legendItems.forEach((item: any) => item.addEventListener('click', handleFilter));
  };

  return (
    <div id="display-circlets" style={{ cursor: "pointer" }}>
      <div
        className="display-box"
        // onClick={handleDisplayedCirclets}
      >
        <p id="display-title" onClick={handleShowOptions}>
          <ExpandMoreIcon className="circlet-ref-icon rotate" />
          <span>Ver anillos</span>
        </p>
        <FormGroup>
          <FormControlLabel
            className="circlet-option hidden"
            id="controlAll"
            control={
              <Checkbox
                checked={checked[0] && checked[1] && checked[2]}
                indeterminate={
                  checked[0] !== checked[1] &&
                  checked[0] !== checked[2] &&
                  checked[1] !== checked[2]
                }
                onChange={handleChangeAll}
              />
            }
            label="Todos"
          />
          {children}
        </FormGroup>
      </div>
    </div>
  );
};
