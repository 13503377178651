import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Map, {
  CircleLayer,
  Source,
  Layer,
  MapRef,
  MapLayerMouseEvent,
  GeoJSONSource,
} from "react-map-gl";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import { getWayfindingAndPromotionsByIdUser } from "../../../service/WayfindingServices/WayfindingService";
import { getAllFormat } from "../../../service/FormatService/FormatService";

import AedasLoading from "Components/Shared/AedasLoading/AedasLoading";
// import { AedasLoading } from "Components/Shared/AedasLoading/AedasLoading";

import { Legend } from "./Legend/Legend";
import { MapFilter } from "./MapFilter/MapFilter";
import { CircletOptions } from "./CircletsOptions/CircletOptions";

import { Layers } from "./Layers/Layers";
import { MapModals } from "./MapModals/MapModals";

import "./Map.css";

export const MainMap: FC<any> = (props: any) => {
  const params: any = useParams();

  const INITIAL_VIEW = {
    longitude: -3.7038293713725383,
    latitude: 40.416904892379016,
    zoom: 6,
  };

  const [lng, setLng] = useState<number>(INITIAL_VIEW.longitude);
  const [lat, setLat] = useState<number>(INITIAL_VIEW.latitude);
  const [zoom, setZoom] = useState<number>(props.zoom | INITIAL_VIEW.zoom);
  const [layerDisplaySatelite, setLayerDisplaySatelite] = useState(false);

  const [cursor, setCursor] = useState<string>("auto");

  const [openEditModalShow, setOpenEditModalShow] = useState<boolean>(false);

  const user = useSelector((state: any) => state.userReducer.user.user);

  const [formats, isFetchingFormats] = useFetch(getAllFormat);
  const [promotionsDW, isFetchingPromotionsDW] = useFetch(getAllPromotions);
  const [PromosAndWayfindings, isFetchingPromosAndWayfindings, updateData] =
    useFetch(getWayfindingAndPromotionsByIdUser, user.id);

  const [promotionsByUser, setPromotionsByUser] = useState<any[]>([]);
  const [wayfindingByUser, setWayfindingByUser] = useState<any[]>([]);

  const [wfAndPromosFiltered, setWfAndPromosFiltered] = useState<any>(null);

  const isLoading = useIsLoading([
    isFetchingFormats,
    isFetchingPromotionsDW,
    isFetchingPromosAndWayfindings,
  ]);

  const [isLoadingFilter, setisLoadingFilter] = useState(false);

  const [flag, setflag] = useState(false);
  const [flagReset, setFlagReset] = useState<boolean>(true);

  const [selectedRow, setSelectedRow] = useState();

  const [legendFilter, setLegendFilter] = useState<any[]>([]);

  const [promotions, setPromotions] = useState<any>(null);
  const [wayfindings, setWayfindings] = useState<any>(null);

  const [wfSelectedFormat, setWfSelectedFormat] = useState();

  const [layer1kmVisible, setLayer1kmVisible] = useState<CircleLayer>({
    ...Layers.promoRadius1km,
  });
  const [layer3kmVisible, setLayer3kmVisible] = useState<CircleLayer>({
    ...Layers.promoRadius3km,
  });
  const [layer7kmVisible, setLayer7kmVisible] = useState<CircleLayer>({
    ...Layers.promoRadius7km,
  });

  const [filters, setFilters] = useState();
  const [filterOptions, setFilterOptions] = useState<any>();

  console.log("Wayfinding 1.5");
  const createGeoJson = (features: any) => {
    return {
      type: "FeatureCollection",
      features: features,
    };
  };

  const wayfindingsTypes = [
    "Banderola",
    "Lona",
    "Monoposte",
    "Mupis-Opis",
    "Otros",
    "Valla",
    "Vinilo",
  ];

  const soldStatus = [
    "En comercialización",
    "Totalmente vendidas",
    "Totalmente entregadas",
  ];

  const emptyFilterData: any = {
    promotion: [],
    dt: [],
    type: [],
    sold: [],
    incidence: false,
  };

  const coodWFCreated =
    Object.keys(params).length > 0
      ? params.coords
          .slice(0, params.coords.length - 1)
          .split(",")
          .map(Number)
      : [];

  useEffect(() => {
    if (
      PromosAndWayfindings &&
      PromosAndWayfindings.promotions &&
      promotionsDW
    ) {
      const promos = promotionsDW.filter((promoDW: any) =>
        PromosAndWayfindings?.promotions.find(
          (promo: any) => promo.id == promoDW.id
        )
      );
      setPromotionsByUser(promos);
      setWayfindingByUser(PromosAndWayfindings?.wayfindings);
    }
  }, [PromosAndWayfindings, promotionsDW]);

  useEffect(() => {
    if (promotionsByUser) {
      const promoByUserFeatures = promotionsByUser.map((item: any) => {
        let gap = 0;

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.longitud + gap, item.latitud + gap],
          },
          properties: {
            title: item.proyecto_prinex,
            id: parseInt(item.id),
            id_dt: item.id_delegacion,
            sold: item.status !== -1 ? soldStatus[item.status] : "Default",
          },
        };
      });

      setPromotions(createGeoJson(promoByUserFeatures));
    }

    if (wayfindingByUser) {
      const wfByUserFeatures = wayfindingByUser.map((item: any) => {
        let gap = 0;

        const format: any = formats?.find(
          (format: any) => format.id === item.id_format
        );

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.long + gap, item.lat + gap],
          },
          properties: {
            WF_id: item.id,
            WFtype:
              format.id == 3
                ? format?.name.replace("/", "-")
                : format?.name || "Otros",
            wfObject: item,
            circlet: item.circlet,
            id_promotion: item.id_promotion,
            id_territorial_delegation: item.id_territorial_delegation,
            id_format: item.id_format,
            incidence: item.incidences,
          },
        };
      });

      setWayfindings(createGeoJson(wfByUserFeatures));
    }

    if (coodWFCreated.length > 0) {
      setLat(coodWFCreated[0]);
      setLng(coodWFCreated[1]);
      setZoom(18);
    }
  }, [promotionsByUser, wayfindingByUser]);

  useEffect(() => {
    if (wayfindingByUser) {
      const type: any[] = [];

      legendFilter?.map((item: any) => {
        const format: any = formats?.find(
          (format: any) => format.name === item
        );
        type.push(format);
      });

      handleFilter({ ...emptyFilterData, type: type });
    }
  }, [legendFilter]);

  const mapRef = useRef<MapRef | null>(null);
  const mapRefCallback = useCallback(
    (ref: MapRef | null) => {
      if (ref !== null) {
        //Set the actual ref we use elsewhere
        mapRef.current = ref;
        const map = ref;

        const loadImages = () => {
          soldStatus.forEach((type, index) => {
            let pathIcon = `/Assets/img/icons/building-solid-sold${index}.png`;

            if (!map.hasImage(`${type}-icon`)) {
              map.loadImage(pathIcon, (error: any, image: any) => {
                if (error) throw error;

                map.addImage(`${type}-icon`, image);
              });
            }
          });

          wayfindingsTypes.forEach((type) => {
            let pathIcon = `/Assets/img/icons/${type}-color.png`;

            if (!map.hasImage(`${type}-icon`)) {
              map.loadImage(pathIcon, (error: any, image: any) => {
                if (error) throw error;

                map.addImage(`${type}-icon`, image);
              });
            }
          });

          if (!map.hasImage("default-promo")) {
            map.loadImage(
              "/Assets/img/icons/building-solid.png",
              (error: any, image: any) => {
                if (error) throw error;

                map.addImage("default-promo", image);
              }
            );
          }

          if (!map.hasImage("default")) {
            map.loadImage(
              "/Assets/img/icons/thumbtack-solid.png",
              (error: any, image: any) => {
                if (error) throw error;

                map.addImage("default", image);
              }
            );
          }
        };

        loadImages();
      }
    },
    [wfAndPromosFiltered, layerDisplaySatelite]
  );

  const openWFCard = (e: MapLayerMouseEvent) => {
    if (e.features) {
      setSelectedRow(JSON.parse(e?.features[0]?.properties?.wfObject));
      setWfSelectedFormat(e?.features[0]?.properties?.WFtype);
      setOpenEditModalShow(!openEditModalShow);
    }
  };

  const handleMouseEnter = useCallback(() => setCursor("pointer"), []);
  const handleMouseLeave = useCallback(() => setCursor("auto"), []);

  const handleShowCirclets = (event: any) => {
    setLayer1kmVisible({
      ...Layers.promoRadius1km,
      layout: { visibility: event[0] ? "visible" : "none" },
    });
    setLayer3kmVisible({
      ...Layers.promoRadius3km,
      layout: { visibility: event[1] ? "visible" : "none" },
    });
    setLayer7kmVisible({
      ...Layers.promoRadius7km,
      layout: { visibility: event[2] ? "visible" : "none" },
    });
  };

  const handleFilter = (data: any) => {
    setFilters(data);

    if (wayfindings && promotions) {
      let promoFiltered: any[] = [];
      let wfFiltered: any[] = [];

      let wayfindingsByFormat: any = wayfindings.features;
      let wayfindingsByPromotions: any = wayfindings.features;
      let wayfindingsByDT: any = wayfindings.features;
      let wayfindingsByInc: any = wayfindings.features;
      let wayfindingsBySoldStatus: any = wayfindings.features;
      let promosByFormat: any = promotions.features;
      let promosByPromotions: any = promotions.features;
      let promosByDT: any = promotions.features;
      let promosByInc: any = promotions.features;
      let promosBySoldStatus: any = promotions.features;

      const resetData: any = {
        type: () => {
          wayfindingsByFormat = [];
          promosByFormat = [];
        },
        promotion: () => {
          wayfindingsByPromotions = [];
          promosByPromotions = [];
        },
        dt: () => {
          wayfindingsByDT = [];
          promosByDT = [];
        },
        sold: () => {
          wayfindingsBySoldStatus = [];
          promosBySoldStatus = [];
        },
        incidence: () => {
          wayfindingsByInc = [];
          promosByInc = [];
        },
      };

      const filterActions: any = {
        type: (criteria: any) => {
          wayfindingsByFormat.push(
            ...wayfindings.features.filter(
              (wf: any) => wf.properties.id_format == criteria.id
            )
          );
          promosByFormat.push(
            ...promotions.features.filter((promo: any) =>
              wayfindingsByFormat.find(
                (wf: any) =>
                  wf.properties.incidence == data["incidence"] &&
                  wf.properties.id_promotion == promo.properties.id
              )
            )
          );
        },
        promotion: (criteria: any) => {
          wayfindingsByPromotions.push(
            ...wayfindings.features.filter(
              (wf: any) => wf.properties.id_promotion == criteria.id
            )
          );
          promosByPromotions.push(
            ...promotions.features.filter(
              (promo: any) => promo.properties.id == criteria.id
            )
          );
        },
        dt: (criteria: any) => {
          wayfindingsByDT.push(
            ...wayfindings.features.filter(
              (wf: any) =>
                wf.properties.id_territorial_delegation == criteria.id
            )
          );
          promosByDT.push(
            ...promotions.features.filter(
              (promo: any) => promo.properties.id_dt == criteria.id
            )
          );
        },
        incidence: (criteria: boolean) => {
          wayfindingsByInc = wayfindings.features.filter(
            (wf: any) => wf.properties.incidence == criteria
          );

          promosByInc.push(
            ...promotions.features.filter((promo: any) =>
              wayfindingsByInc.find(
                (wf: any) => wf.properties.id_promotion == promo.properties.id
              )
            )
          );
        },
        sold: (criteria: any) => {
          promosBySoldStatus.push(
            ...promotions.features.filter(
              (promo: any) => promo.properties.sold == criteria.name
            )
          );
          wayfindingsBySoldStatus.push(
            ...wayfindings.features.filter((wf: any) =>
              promosBySoldStatus.find(
                (promo: any) =>
                  promo.properties.id == wf.properties.id_promotion
              )
            )
          );
        },
      };

      Object.entries(data).forEach((category: any) => {
        if (category[0] == "incidence" && category[1]) {
          resetData[category[0]]();
          filterActions[category[0]](category[1]);
        } else {
          if (category[1].length > 0) {
            resetData[category[0]]();
            category[1].forEach((criteria: any) => {
              filterActions[category[0]](criteria);
            });
          }
        }
      });

      wfFiltered = wayfindings.features?.filter(
        (wf: any) =>
          wayfindingsByFormat.includes(wf) &&
          wayfindingsByDT.includes(wf) &&
          wayfindingsByPromotions.includes(wf) &&
          wayfindingsByInc.includes(wf) &&
          wayfindingsBySoldStatus.includes(wf)
      );

      promoFiltered = promotions.features?.filter(
        (promo: any) =>
          promosByPromotions.includes(promo) &&
          promosByFormat.includes(promo) &&
          promosByDT.includes(promo) &&
          promosByInc.includes(promo) &&
          promosBySoldStatus.includes(promo)
      );

      const wfFilteredData: any = createGeoJson(wfFiltered);
      const promoFilteredData: any = createGeoJson(promoFiltered);

      if (promoFiltered.length == 1) {
        mapRef.current?.flyTo({
          center: promoFiltered[0].geometry.coordinates,
          zoom: 14,
        });
      } else {
        mapRef.current?.flyTo({
          center: [INITIAL_VIEW.longitude, INITIAL_VIEW.latitude],
          zoom: INITIAL_VIEW.zoom,
        });
      }

      setWfAndPromosFiltered({
        promos: promoFilteredData,
        wayfindings: wfFilteredData,
      });
    }
  };

  const handleFilterReset = () => {
    setFilters(emptyFilterData);
    setWfAndPromosFiltered(null);

    const currentWFSource = mapRef.current?.getSource(
      "wayfindings"
    ) as GeoJSONSource;
    const currentPromoSource = mapRef.current?.getSource(
      "promo"
    ) as GeoJSONSource;

    if (wayfindings && promotions) {
      currentPromoSource.setData(promotions);
      currentWFSource.setData(wayfindings);
    }

    mapRef.current?.flyTo({
      center: [INITIAL_VIEW.longitude, INITIAL_VIEW.latitude],
      zoom: INITIAL_VIEW.zoom,
    });
  };

  const wfGeoJsonData = useMemo(() => {
    if (wfAndPromosFiltered) {
      return wfAndPromosFiltered.wayfindings;
    } else return wayfindings;
  }, [wayfindings, wfAndPromosFiltered]);

  const promoGeoJsonData = useMemo(() => {
    if (wfAndPromosFiltered) {
      return wfAndPromosFiltered.promos;
    } else return promotions;
  }, [promotions, wfAndPromosFiltered]);

  return (
    <>
      {isLoading || isLoadingFilter ? (
        <div className="loadingMap">
          <AedasLoading />
        </div>
      ) : (
        <>
          <Map
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN || ""}
            initialViewState={{
              longitude: lng,
              latitude: lat,
              zoom: zoom,
            }}
            style={{ width: "100vw", height: props.height }}
            mapStyle={
              layerDisplaySatelite
                ? "mapbox://styles/mapbox/satellite-v9"
                : "mapbox://styles/mapbox/streets-v11"
            }
            styleDiffing={false}
            ref={mapRefCallback}
            interactiveLayerIds={["wf-icons"]}
            onClick={openWFCard}
            cursor={cursor}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Source
              id="promo"
              type="geojson"
              data={promoGeoJsonData}
              cluster={true}
              clusterMaxZoom={13}
            >
              <Layer {...Layers.promoLayer} />
              <Layer {...Layers.clusterPromo} />
              <Layer {...Layers.clusterPromoCounter} />
              <Layer {...layer1kmVisible} />
              <Layer {...layer3kmVisible} />
              <Layer {...layer7kmVisible} />
            </Source>

            <Source
              id="wayfindings"
              type="geojson"
              data={wfGeoJsonData}
              cluster={true}
              clusterMaxZoom={13}
            >
              <Layer {...Layers.WFLayer} />
              <Layer {...Layers.clusterWF} />
              <Layer {...Layers.clusterWFCounter} />
              <Layer {...Layers.WFWithIncidence} />
            </Source>
          </Map>

          <MapFilter
            handleFilter={handleFilter}
            filters={filters}
            handleFilterReset={handleFilterReset}
            setFilterOptions={setFilterOptions}
          />

          <div className="map-display-options">
            <div id="display-options">
              <div
                className={`display-box ${
                  !layerDisplaySatelite ? "satelite" : "calles"
                }`}
                onClick={() => setLayerDisplaySatelite(!layerDisplaySatelite)}
              >
                <span>{!layerDisplaySatelite ? "satelite" : "calles"}</span>
              </div>
            </div>

            <CircletOptions
              handleShowCirclets={handleShowCirclets}
              flagReset={flagReset}
            />
          </div>

          <Legend setLegendFilter={setLegendFilter} filters={filters} />

          <MapModals
            openEditModalShow={openEditModalShow}
            setOpenEditModalShow={setOpenEditModalShow}
            selectedRow={selectedRow}
            wfSelectedFormat={wfSelectedFormat}
            formats={formats}
            updateData={updateData}
          />
        </>
      )}
    </>
  );
};
