import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InputAdornment from "@mui/material/InputAdornment";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { LoadingButton } from "@mui/lab";
import { Mapa } from "../Map/Mapa";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";

import MapNewWayfinding from "./MapNewWayfinding";

import { getAllFormat } from "../../../service/FormatService/FormatService";
import { getAllSector } from "../../../service/SectorService/SectorService";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import { getAllDelegations } from "../../../service/DelegationServices/DelegationServices";
import { addNewWayfinding } from "../../../service/WayfindingServices/WayfindingService";
import { useFetch, useIsLoading } from "../../../Hooks/useFetch";

import "./NewWayfinding.css";
import { getPromotionsByIdUser } from "../../../service/PromotionServices/PromotionServices";

const FILE_SIZE = 2000;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = yup.object({
  latitude: yup.number().required("Este campo es requerido"),
  longitude: yup.number().required("Este campo es requerido"),
  format: yup.object().required("Este campo es requerido"),
  // territorial: yup.object().required("Este campo es requerido"),
  promotion: yup.object().required("Este campo es requerido"),
  circlet: yup.number().required("Este campo es requerido"),
  price: yup.number().min(0, 'Ingresa un valor mayor a 0'),
  // sector: yup.object().required("Este campo es requerido"),
  photos: yup.mixed(),
  // photos: yup.mixed().test('fileSize', "La imagen es demasiado grande", value => value.size <= FILE_SIZE)
  //  .test('fileType', "Formato inválido", value => SUPPORTED_FORMATS.includes(value.type) )
});

export const NewWayfinding = () => {
  const history = useHistory();
  const [lngLat, setLngLat] = useState<any>([0, 0]);
  const [isLoadingButton, setisLoadingButton] = useState(false);
  const user = useSelector((state: any) => state.userReducer.user);
  const formik = useFormik({
    initialValues: {
      latitude: -3.7,
      longitude: 40.4,
      format: null,
      promotion: null,
      circlet: null,
      // territorial: null,
      // sector: null,
      price: 0,
      comments: null,
      fromDate: null,
      untilDate: null,
      photos: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      setisLoadingButton(true);

      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      addNewWayfinding(values, user.user.id, filesToSend)
        .then(() => {
          history.push(`/mapa/${values.latitude},${values.longitude}`);
          toast.success("Se ha creado el wayfinding correctamente");
          setisLoadingButton(false);
        })
        .catch(() => {
          toast.error("Error creando el wayfinding");
          setisLoadingButton(false);
        });
    },
  });

  const [step, setStep] = useState(0);
  const [formats, isFetching] = useFetch(getAllFormat);
  const [sectors, isFetchingSectors] = useFetch(getAllSector);
  const [promoDTSector, setPromoDTSector] = useState<any[]>([]);
  const [sectores, setSectores] = useState<any[]>([]);
  const [delegations, isFetchingDelegations] = useFetch(getAllDelegations);
  const [promotions, isFetchingPromotions] = useFetch(getAllPromotions);
  const [Promos, isFetchingPromos, updateDataWF] = useFetch(
    getPromotionsByIdUser,
    user.user.id
  );

  const [files, setFiles] = useState<any[]>([]);
  const isLoading = useIsLoading([
    isFetching,
    isFetchingSectors,
    isFetchingPromotions,
    isFetchingDelegations,
    isFetchingPromos,
  ]);

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFiles(fileListBase64);
  };

  const handleNextStep = (step: number) => {
    if (step !== 1) {
      if (
        formik.values.format === null ||
        formik.values.promotion === null ||
        formik.values.circlet === null ||
        formik.values.photos.length <= 0
      ) {
        toast.warning("Rellena los campos obligatorios antes de continuar");
      } else if (formik.values.promotion === null) {
        toast.warning(
          "Rellena el campo promoción para seguir completando el formulario"
        );
      } else {
        setStep(step);
      }
    } else if (step === 1 && formik.values.promotion === null) {
      toast.warning("Rellena los campos obligatorios antes de continuar");
    } else {
      setStep(step);
    }
  };

  const handleGoBack = () => {
    setStep(step - 1);
  };

  const norepobj_sectores = (arr: any) => {
    let unique: any[] = [];
    let list = arr.filter((el: any) => {
      let isDup = unique.includes(el.sector);
      if (!isDup) {
        unique.push(el.sector);
        return true;
      }
    });
    return list;
  };

  useEffect(() => {
    if (promotions) {
      let promo_dt_sector: any[] = [];
      promotions.map((p: any) =>
        promo_dt_sector.push({
          promo: p.id,
          dt: p.id_delegacion,
          sector: p.sector,
        })
      );
      setSectores(
        norepobj_sectores(promo_dt_sector).map((obj: any, index: number) => {
          return { id: index, name: obj.sector || "" };
        })
      );
      setPromoDTSector(promo_dt_sector);
    }
  }, [promotions]);

  if (isLoading) {
    return (
      <>
        <AedasLoading></AedasLoading>
      </>
    );
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <h2 className="page-title">Crear nuevo</h2>

        <Stepper
          lngLat={lngLat}
          setLngLat={setLngLat}
          isLoadingButton={isLoadingButton}
          formats={formats}
          formik={formik}
          step={step}
          handleNextStep={handleNextStep}
          handleGoBack={handleGoBack}
          handleUploadFiles={handleUploadFiles}
          promotions={promotions.filter((promo: any) =>
            Promos.promotions.find((p: any) => p.id == promo.id)
          )}
          sectors={sectors}
          sectores={sectores}
          promoDTSector={promoDTSector}
          delegations={delegations}
        />
      </form>
    </>
  );
};

const Stepper = (props: any) => {
  const {
    lngLat,
    setLngLat,
    step,
    setMarkerCord,
    handleNextStep,
    formik,
    handleGoBack,
    handleUploadFiles,
    formats,
    promotions,
    sectors,
    promoDTSector,
    sectores,
    delegations,
    isLoadingButton,
  } = props;

  const sorterFunction = (a: any, b: any) => {
    if (a.proyecto_prinex < b.proyecto_prinex) return -1;
    if (a.proyecto_prinex > b.proyecto_prinex) return 1;
    return 0;
  };

  const sorterFormats = (a: any, b: any) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  promotions?.sort(sorterFunction);
  formats?.sort(sorterFormats);

  switch (step) {
    case 0:
      return (
        <div className="map-container">
          <div style={{ width: "100%", padding: "10px" }}>
            <Autocomplete
              fullWidth
              id="promotion"
              options={promotions}
              getOptionLabel={(option: any) => option.proyecto_prinex}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.proyecto_prinex}
                </li>
              )}
              onChange={(e, value) => {
                console.log(value);
                setLngLat([
                  parseFloat(value.longitud),
                  parseFloat(value.latitud),
                ]);
                formik.setFieldValue("promotion", value);
                formik.setFieldValue(
                  "territorial",
                  delegations.find(
                    (d: any) => d.idDelegacion === value.id_delegacion
                  )
                );
                let relacion = promoDTSector.find(
                  (p: any) => p.promo === value.id
                );
                console.log(relacion);
                let sector =
                  sectores.find((s: any) => relacion.sector === s.name) || "";
                formik.setFieldValue("sector", sector);

                console.log(
                  promoDTSector,
                  delegations,
                  sectores.find((s: any) => relacion.sector === s.name)
                );
              }}
              value={formik.values.promotion}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Promoción *"
                  error={
                    formik.touched.promotion && Boolean(formik.errors.promotion)
                  }
                  helperText={
                    formik.touched.promotion && formik.errors.promotion
                  }
                />
              )}
            />
          </div>
          <MapNewWayfinding lngLat={lngLat} formik={formik}/>

          <Button
            className="button save_button"
            onClick={() => handleNextStep(1)}
          >
            Siguiente
          </Button>
        </div>
      );

    case 1:
      return (
        <Box
          component="div"
          sx={{
            width: "70%",
            p: "3rem",
            mx: "auto",
            my: 4,
            backgroundColor: "white",
            borderRadius: "1rem",
          }}
          className="text-center"
          // onSubmit={handleSave}
        >
          <div className="row">
            <div className="col-6 ">
              {/*<div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="latitude"
                  label="Latitud"
                  variant="filled"
                  disabled
                  type={"number"}
                  value={formik.values.latitude}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.latitude && Boolean(formik.errors.latitude)
                  }
                  helperText={formik.touched.latitude && formik.errors.latitude}
                />
              </div>*/}
              <div style={{ width: "100%", padding: "10px" }}>
                <Autocomplete
                  fullWidth
                  id="format"
                  options={formats}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) => {
                    formik.setFieldValue("format", value);
                  }}
                  value={formik.values.format}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Formato *"
                      error={
                        formik.touched.format && Boolean(formik.errors.format)
                      }
                      helperText={formik.touched.format && formik.errors.format}
                    />
                  )}
                />
              </div>
              <div style={{ width: "100%", padding: "10px" }}>
                <Autocomplete
                  id="circlet"
                  fullWidth
                  options={["1", "2", "3"]}
                  getOptionLabel={(option: any) => option}
                  onChange={(e, value) => {
                    formik.setFieldValue("circlet", value);
                  }}
                  value={formik.values.circlet}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Anillo *"
                      value={formik.values.circlet}
                      error={
                        formik.touched.circlet && Boolean(formik.errors.circlet)
                      }
                      helperText={
                        formik.touched.circlet && formik.errors.circlet
                      }
                    />
                  )}
                />
              </div>
              {/* <div style={{ width: "100%", padding: "10px" }}>
                <Autocomplete
                  fullWidth
                  id="sector"
                  options={sectores}
                  getOptionLabel={(option: any) => {
                    return option.name
                  }}
                  onChange={(e, value) => {
                    
                    formik.setFieldValue("sector", value);
                  }}
                  disabled={formik.values.promotion !== null}
                  value={formik.values.sector}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sector"
                      error={
                        formik.touched.sector && Boolean(formik.errors.sector)
                      }
                      helperText={formik.touched.sector && formik.errors.sector}
                    />
                  )}
                />
              </div> */}
            </div>
            <div className="col-6">
              {/*<div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="longitude"
                  label="Longitud"
                  variant="filled"
                  disabled
                  type={"number"}
                  value={formik.values.longitude}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.longitude && Boolean(formik.errors.longitude)
                  }
                  helperText={
                    formik.touched.longitude && formik.errors.longitude
                  }
                />
                </div>
              <div style={{ width: "100%", padding: "10px" }}>
                <Autocomplete
                  fullWidth
                  id="promotion"
                  options={promotions}
                  getOptionLabel={(option: any) => option.proyecto_prinex}
                  onChange={(e, value) => {
                    formik.setFieldValue("promotion", value);
                    formik.setFieldValue(
                      "territorial",
                      delegations.find((d: any) => d.id === value.id_delegacion)
                    );
                    let relacion = promoDTSector.find((p:any) => p.promo === value.id )

                    formik.setFieldValue("sector",sectores.find((s:any) => relacion.sector === s.name))
                    
                    
                  }}
                  
                  value={formik.values.promotion}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Promoción *"
                      error={
                        formik.touched.promotion &&
                        Boolean(formik.errors.promotion)
                      }
                      helperText={
                        formik.touched.promotion && formik.errors.promotion
                      }
                    />
                  )}
                />
              </div>*/}
              {/* <div style={{ width: "100%", padding: "10px" }}>
                <Autocomplete
                  fullWidth
                  id="territorial"
                  options={delegations}
                  getOptionLabel={(option: any) =>  option.name} 
                  onChange={(e, value) => {
                    formik.setFieldValue("territorial", value);
                  }}
                  value={formik.values.territorial}
                  disabled={formik.values.promotion !== null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dirección Territorial"
                      error={
                        formik.touched.territorial &&
                        Boolean(formik.errors.territorial)
                      }
                      helperText={
                        formik.touched.territorial && formik.errors.territorial
                      }
                    />
                  )}
                />
              </div> */}
              <div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    multiple: true,
                    accept: "image/png, image/jpeg",
                  }}
                  fullWidth
                  id="photos"
                  label="Fotos *"
                  type={"file"}
                  // value={formik.values.photos }
                  onChange={(event: any) => {
                    handleUploadFiles(event.currentTarget.files[0], event);
                  }}
                  error={formik.touched.photos && Boolean(formik.errors.photos)}
                  helperText={formik.touched.photos && formik.errors.photos}
                />
              </div>
            </div>
          </div>
          <div></div>

          <div className="input-container">
            <Button className="back_button" onClick={handleGoBack}>
              Volver
            </Button>
            <Button
              className="button save_button"
              onClick={() => handleNextStep(2)}
            >
              siguiente
            </Button>
          </div>
        </Box>
      );

    case 2:
      return (
        <Box
          component="div"
          sx={{
            width: "70%",
            p: "2rem",
            mx: "auto",
            my: 4,
            backgroundColor: "white",
            borderRadius: "25px",
          }}
          className="text-center"
          // onSubmit={handleSave}
        >
          <div className="row">
            <div className="col-6">
              <div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="fromDate"
                  label="Fecha desde"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  type={"date"}
                  value={formik.values.fromDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fromDate && Boolean(formik.errors.fromDate)
                  }
                  helperText={formik.touched.fromDate && formik.errors.fromDate}
                />
              </div>
              <div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="price"
                  type='number'
                  label="Precio"
                  variant="outlined"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />{" "}
              </div>
            </div>
            <div className="col-6">
              <div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="untilDate"
                  label="Fecha hasta"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  type={"date"}
                  disabled={formik.values.fromDate === null}
                  inputProps={{
                    min: formik.values.fromDate,
                  }}
                  value={formik.values.untilDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.untilDate && Boolean(formik.errors.untilDate)
                  }
                  helperText={
                    formik.touched.untilDate && formik.errors.untilDate
                  }
                />
              </div>
              <div style={{ width: "100%", padding: "10px" }}>
                <TextField
                  fullWidth
                  id="comments"
                  label="Anotaciones"
                  variant="outlined"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />{" "}
              </div>
            </div>
          </div>

          <div className="input-container">
            <Button className="back_button" onClick={handleGoBack}>
              Volver
            </Button>
            {isLoadingButton ? (
              <Button>
                <CircularProgress
                  style={{ marginTop: "1rem", color: "var(--white-manz)" }}
                  size={16}
                />
              </Button>
            ) : (
              <Button className="save_button" type="submit" color="primary">
                Crear
              </Button>
            )}
          </div>
        </Box>
      );

    default:
      return <div></div>;
  }
};
