import { LoadingButton } from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
function RecoveryPassword() {
  return (
    <div className="new_user_container">
      <div className="box_new_user">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <img
            style={{ height: "100px" }}
            src={"/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"}
          ></img>{" "}
          <h2
            style={{
              fontFamily: "var(--lis-title)",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Applicación de wayfinding
          </h2>
          <h5 style={{ fontFamily: "var(--lis-title)", textAlign: "center" }}>
            <a
              style={{
                fontFamily: "var(--lis-title)",
                fontWeight: "bold",
                textAlign: "center",
              }}
            ></a>{" "}
            Introduca el email para recuperar su contraseña. Si el email esta
            registrado se le enviara un correo electronico con un link para
            resuperar su contraseña.
          </h5>
        </div>
        <RecoveryPaswwordForm></RecoveryPaswwordForm>
      </div>
    </div>
  );
}

export default RecoveryPassword;

function RecoveryPaswwordForm() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: null,
    onSubmit: async (values) => {
      axios
        .post(
          process.env.REACT_APP_API_BACKEND +
            "/external_user/recover/?email=" +
            values.email,
          {}
        )
        .then(() => {
          toast.success("Se ha enviado");
          history.push("/login");
        })
        .catch(() => {
          toast.success("Se ha enviado");
          history.push("/login");
        });
    },
  });

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="p-2">
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          ></TextField>
        </div>
        <div className="p-2">
          <LoadingButton
            color="primary"
            style={{
              backgroundColor: "var(--blue-lis)",
              fontFamily: "var(--lis-title)",
            }}
            variant="contained"
            fullWidth
            loadingIndicator={<CircularProgress size={16} />}
            loading={isLoading}
            type="submit"
          >
            Enviar
          </LoadingButton>
        </div>
      </form>
    </>
  );
}
