import axios from "axios";
import { toast } from "react-toastify";

export const getAllWayfindings = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/wayfinding/", config);
};

export const getWayfindingById = (wf_id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(process.env.REACT_APP_API_BACKEND + "/wayfinding/" + wf_id, config)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
export const getWayfindingAndPromotionsByIdUser = (id_user: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/wayfinding/user/" + id_user,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
export const getWayfindingPhotos = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/wayfinding/photos/" + params,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const addNewWayfinding = (data: any, user_id: number, files: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  const startContactDate = data.fromDate ? data.fromDate + "T00:00" : null;
  const finishContactDate = data.untilDate ? data.untilDate + "T00:00" : null;

  const body = {
    lat: data.latitude,
    long: data.longitude,
    monthly_price: data.price,
    start_contact_date: startContactDate,
    finish_contact_date: finishContactDate,
    comments: data.comments,
    circlet: data.circlet,
    id_format: data.format.id,
    id_territorial_delegation: data.territorial.idDelegacion,
    territorial_name: data.territorial.NombreDelegacion,
    id_promotion: data.promotion.id,
    promotion_name: data.promotion.proyecto_prinex,
    promotion_coords: [data.promotion.latitud, data.promotion.longitud],
    id_sector: data.sector.id,
    active: true,
    id_user: user_id,
    photos: files,
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/wayfinding/",
    body,
    config
  );
};

export const updateWayfinding = (
  data: any,
  user_id: number,
  files: any,
  id_wf: number
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  const startContactDate = data.fromDate ? data.fromDate + "T00:00" : null;
  const finishContactDate = data.untilDate ? data.untilDate + "T00:00" : null;

  //! FIX CORDENADAS: SOP-19827
  const latitud = data?.promotion?.latitud ?? null;
  const longitud = data?.promotion?.longitud ?? null;
  const promotion_coords =
    latitud !== null &&
    longitud !== null &&
    latitud !== undefined &&
    longitud !== undefined
      ? [latitud, longitud]
      : null;

  const body = {
    id: id_wf,
    lat: data.latitude,
    long: data.longitude,
    monthly_price: data.price,
    start_contact_date: startContactDate,
    finish_contact_date: finishContactDate,
    comments: data.comments,
    circlet: data.circlet,
    id_format: data.format.id,
    id_territorial_delegation: data?.promotion?.id_delegacion,
    id_promotion: data?.promotion?.id,
    promotion_name: data?.promotion?.proyecto_prinex,
    promotion_coords: promotion_coords,
    active: true,
    id_user: user_id,
    photos: files,
  };

  return axios
    .put(
      process.env.REACT_APP_API_BACKEND +
        "/wayfinding/" +
        id_wf +
        "?id_user=" +
        user_id,
      body,
      config
    )

    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const deleteWayfindingById = (wf_id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .delete(process.env.REACT_APP_API_BACKEND + "/wayfinding/" + wf_id, config)
    .then(() => toast.success("Se ha borrado correctamente"))
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const deleteWayfindingPhoto = (
  photos: any,
  way_id: number,
  prom_id: number
) => {
  const body: any = {
    photos: photos,
    way_id,
    prom_id,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/wayfinding/photo/",
    body,
    config
  );
};
