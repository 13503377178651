import axios from "axios";

export const getAllUsers = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/user/", config);
};

export const getAllUsersEmails = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/autocomplete/allEmployeeEmails",
    config
  );
};

export const addNewInternalUser = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/internal_user/",
    value,
    config
  );
};

export const addNewExternalUser = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/new_user/",
    value,
    config
  );
};

export const getUserByToken = (value: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/new_user/token/" + value,
    config
  );
};

export const setPasswordNewUser = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/new_user/altaUsuario/",
    value,
    config
  );
};

export const getAllRols = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND + "/rol/", config);
};

export const getUserByTokenRecovery = (value: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/recovery_password/recover/" + value,
    config
  );
};
export const setPasswordRecovery = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/recovery_password/changePass/",
    value,
    config
  );
};

export const updateUser = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/user/" + value.id,
    value,
    config
  );
};

export const reAsingProms = (value: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/user/prom/user/",
    value,
    config
  );
};
