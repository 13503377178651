import { CircleLayer, SymbolLayer } from 'react-map-gl';

interface InterfaceLayer {
  promoLayer: SymbolLayer,
  WFLayer: SymbolLayer,
  clusterPromo: CircleLayer,
  clusterPromoCounter: SymbolLayer,
  promoRadius1km: CircleLayer,
  promoRadius3km: CircleLayer,
  promoRadius7km: CircleLayer,
  clusterWF: CircleLayer,
  clusterWFCounter: SymbolLayer,
  WFWithIncidence: CircleLayer,
}

const LATITUDE = 40.416904892379016;
const metersToPixelMaxZoom = (meters: number, latitude: number = LATITUDE) => {
  //Get radius in pixel depends on meters to display and latitud, at max zoom (22)
  return meters / 0.019 / Math.cos((latitude * Math.PI) / 180);
};

export const Layers: InterfaceLayer = {
  promoLayer: {
    id: "promotions",
    type: "symbol",
    layout: {
      visibility: "visible",
      "icon-size": 0.6,
      "icon-image": [
        "match",
        ["string", ["get", "sold"]],
        "En comercialización",
        "En comercialización-icon",
        "Totalmente vendidas",
        "Totalmente vendidas-icon",
        "Totalmente entregadas",
        "Totalmente entregadas-icon",
        "Deafult",
        "default-promo",
        "default-promo",
      ],

      "text-allow-overlap": true,
      "icon-allow-overlap": true,

      // get the title name from the source's "title" property
      "text-field": ["get", "title"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.25],
      "text-anchor": "top",
    },   
  },
  WFLayer: {
    id: "wf-icons",
    type: "symbol",
    layout: {
      "icon-size": 0.8,
      "icon-image": [
        "match",
        ["string", ["get", "WFtype"]],
        "Lona",
        "Lona-icon",
        "Monoposte",
        "Monoposte-icon",
        "Valla",
        "Valla-icon",
        "Mupis-Opis",
        "Mupis-Opis-icon",
        "Banderola",
        "Banderola-icon",
        "Vinilo",
        "Vinilo-icon",
        "Otros",
        "Otros-icon",
        "default",
      ],
      "text-allow-overlap": true,
      "icon-allow-overlap": true,
      // get the title name from the source's "type_id" property
      "text-field": ["get", "type_id"],
      "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      "text-offset": [0, 1.25],
      "text-anchor": "top",
    },
  },
  clusterPromo: {
    id: "clusters-promotions",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#092e3b",
      "circle-radius": 20,
    },
  },
  clusterPromoCounter: {
    id: "clusters-promotions-count",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Arial Unicode MS Bold"],
      "text-size": 14,
      "text-allow-overlap": true,
    },
    paint: {
      "text-color": "#fff",
    },
  },
  promoRadius1km: {
    id: "circlet1km",
    type: "circle",
    minzoom: 10,
    maxzoom: 20,
    layout: {
      // visibility: `${layer1kmVisible ? "visible" : "none"}`,
      visibility: "visible",
    },
    paint: {
      "circle-color": "#041C2C",
      "circle-opacity": 0.1,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#041C2C", //PMS 296C
      "circle-radius": {
        stops: [
          [0, 0],
          [22, metersToPixelMaxZoom(1000)],
        ],
        base: 2,
      },
    },
  },
  promoRadius3km: {
    id: "circlet3km",
    type: "circle",
    minzoom: 10,
    maxzoom: 13,
    layout: {
      // visibility: `${layer3kmVisible ? "visible" : "none"}`,
      visibility: "visible",
    },
    paint: {
      "circle-color": "#009CA6",
      "circle-opacity": 0.1,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#009CA6", //PMS 320C
      "circle-radius": {
        stops: [
          [0, 0],
          [22, metersToPixelMaxZoom(3000)],
        ],
        base: 2,
      },
    },
  },
  promoRadius7km: {
    id: "circlet7km",
    type: "circle",
    minzoom: 8,
    maxzoom: 12,
    layout: {
      // visibility: `${layer7kmVisible ? "visible" : "none"}`,
      visibility: "visible",
    },
    paint: {
      "circle-color": "#BBBCBC",
      "circle-opacity": 0.1,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#BBBCBC", //PMS Cool grey4C
      "circle-radius": {
        stops: [
          [0, 0],
          [22, metersToPixelMaxZoom(7000)],
        ],
        base: 2,
      },
    },
  },
  clusterWF: {
    id: "clusters",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": "#0088a6",
      "circle-radius": 20,
    },
  },
  clusterWFCounter: {
    id: "cluster-count",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Arial Unicode MS Bold"],
      "text-size": 14,
      "text-allow-overlap": true,
    },
    paint: {
      "text-color": "#fff",
    },
  },

  WFWithIncidence: {
    id: "wf-with-incidence",
    type: "circle",
    filter: [">", ["get", "incidence"] , 0],
    paint: {
      "circle-color": "red",
      "circle-radius": 7,
      "circle-translate": [10, -12],
    },
  }
}