import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Legend = (props: any) => {
	const typeFilterApplied = props.filters.type;
	const iconsTypes = [
			'Promoción',
			'Banderola',
			'Lona',
			'Monoposte',
			'Mupis/Opis',
			'Otros',
			'Valla',
			'Vinilo',
	];	
	const iconsSource = [
			'building-solid.svg',
			'flag-solid.svg',
			'rectangle-ad-solid.svg',
			'sign-hanging-solid.svg',
			'bus-simple-solid.svg',
			'signs-post-solid.svg',
			'valla.svg',
			'sheet-plastic-solid.svg',
	]
	
	useEffect(() => {
			const legend = document.getElementById('legend');
	
			iconsTypes.forEach((type, i) => {
					const icon = iconsSource[i];
					const item = document.createElement('div');
					const key = document.createElement('span');
					key.className = 'legend-key';
					item.id = type;
					key.style.backgroundImage = `url(${process.env.PUBLIC_URL}/Assets/img/icons/${icon})`;
					key.style.backgroundRepeat = 'no-repeat'
					key.style.backgroundPosition = 'center'
					key.style.backgroundSize = 'contain'
		
					// ToDo: avoid legend duplication
					// const element = document.getElementById(`${item}`);          

					const value = document.createElement('span');
					value.innerHTML = `${type}`;
					item.appendChild(key);
					item.classList.add('item-container', 'hidden');
					item.appendChild(value);

					if ( legend ) {
							legend.appendChild(item);
							// item.setAttribute("onClick", "() => handleFilter()")
					}
			})
	}, []);

	useEffect(()=> {
		const legendItems = document.querySelectorAll('.legend-key + span');

		legendItems.forEach((legend: any) => {		
			if (legend.classList.contains('selected')) {
				legend.classList.remove('selected');		
			}

			if ( typeFilterApplied.length > 0 ) {
					typeFilterApplied.forEach((typeFiltered: any) => {			
						if (typeFiltered.name === legend.textContent) {
							legend.classList.add('selected');
						}
					})				
			}
		})
	}, [typeFilterApplied])

	const handleShowLegend = () => {
		const legendItems = document.querySelectorAll('.item-container');
		const icon = document.querySelector('.legend-ref-icon');

		legendItems.forEach((item: any) => item.classList.toggle('hidden'));
		icon?.classList.toggle('rotate');
		
		legendItems.forEach((item: any) => item.addEventListener('click', handleFilter));
	}
		
	
	const handleFilter = (e: any) => {
		let legendFilterArray: any[] = [];
		const legendItems = document.querySelectorAll('.legend-key + span');
		
		if (e.target.textContent !== 'Promoción') {
			e.target.classList.toggle('selected');

			legendItems.forEach((item: any) => {
				if ( item.classList.contains('selected') ) {
					legendFilterArray.push(item.textContent); 
				}
			})
			props.setLegendFilter(legendFilterArray);
		} else return
	}

  return (
    <div id='legend' className='legend'>
        <div id="legend-title"  onClick={handleShowLegend} >
            <ExpandMoreIcon className='legend-ref-icon rotate' /><span>Leyenda</span>
        </div>
    </div>
  )
}
