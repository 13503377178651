import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";

import {
  addNewIncidence,
  getAllIncTypes,
} from "../../../../service/IncidenceServices/IncidenceService";
import "./NewIncidenceModal.css";
import { useSelector } from "react-redux";

// const FILE_SIZE = 2000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object({
  //type: yup.object().required("Este campo es requerido"),
  photos: yup.mixed(),
  // photos: yup.mixed().test('fileSize', "La imagen es demasiado grande", value => value.size <= FILE_SIZE)
  //  .test('fileType', "Formato inválido", value => SUPPORTED_FORMATS.includes(value.type) )
});

export const NewIncidenceModal = (props: any) => {
  const [incTypes, isFetching] = useFetch(getAllIncTypes);
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: any) => state.userReducer.user?.user);

  const formik = useFormik({
    initialValues: {
      wayfinding: props.selectedFormat,
      type: 1,
      comments: null,
      photos: [],
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      setLoading(true);
      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      addNewIncidence(props.selectedRow.id, values, user.id, filesToSend).then(
        () => {
          // toast.success("Se ha creado la incidencia");
          props.setOpenNewIncidence(false);
          props.updateData();
        }
      );
    },
  });

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFiles(fileListBase64);
  };

  return (
    <Box
      component="form"
      sx={{
        width: "80%",
        p: "3rem",
        mx: "auto",
        my: 4,
        backgroundColor: "white",
        borderRadius: "1rem",
      }}
      noValidate
      autoComplete="off"
      style={{ textAlign: "center" }}
      onSubmit={formik.handleSubmit}
    >
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        <div style={{ width: "100%", padding: "10px" }}>
          <TextField
            id="wayfinding"
            label="Wayfinding"
            variant="filled"
            value={formik.values.wayfinding}
            fullWidth
            disabled
          />
        </div>
        {/* <div style={{ width: "100%", padding: "10px" }}>
          <Autocomplete
            id="type"
            // @ts-ignore: Unreachable code error
            options={incTypes}
            getOptionLabel={(option: any) => option.name}
            onChange={(e, value) => {
              formik.setFieldValue("type", value);
            }}
            value={formik.values.type}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Incidencia"
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
              />
            )}
          />
        </div> */}
        <div style={{ width: "100%", padding: "10px" }}>
          <TextField
            id="comments"
            label="Anotaciones"
            variant="outlined"
            fullWidth
            value={formik.values.comments}
            onChange={formik.handleChange}
            error={formik.touched.comments && Boolean(formik.errors.comments)}
            helperText={formik.touched.comments && formik.errors.comments}
          />
        </div>
        <div style={{ width: "100%", padding: "10px" }}>
          <TextField
            id="photos"
            InputLabelProps={{ shrink: true }}
            label="Fotos"
            variant="outlined"
            inputProps={{
              multiple: true,
              accept: "image/png, image/jpeg",
            }}
            type={"file"}
            onChange={(event: any) => {
              handleUploadFiles(event.currentTarget.files[0], event);
            }}
            error={formik.touched.photos && Boolean(formik.errors.photos)}
            helperText={formik.touched.photos && formik.errors.photos}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AddAPhotoIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {loading ? (
        <Button>
          <CircularProgress
            style={{ marginTop: "1rem", color: "var(--white-manz" }}
            size={16}
          />
        </Button>
      ) : (
        <Button className="save_button" type="submit" color="primary">
          Crear
        </Button>
      )}
    </Box>
  );
};
