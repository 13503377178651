import axios from "axios";
import { toast } from "react-toastify";

export const getIncidences = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/incidence/",
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getIncByWFId = (wf_id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(process.env.REACT_APP_API_BACKEND + "/incidence/wf/" + wf_id, config)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getAllIncTypes = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/indidence_type/",
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const addNewIncidence = (
  wf_id: number,
  data: any,
  user_id: number,
  files: any
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  const body = {
    comment: data.comments,
    id_type: data.type,
    active: true,
    id_wayfinding: wf_id,
    id_user_informer: user_id,
    photos: files,
  };

  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/incidence/",
      body,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const updateIncidence = (incidence_id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/incidence/deactivate/" + incidence_id, 
    config
  );
};
