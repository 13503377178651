import axios from "axios";

export const getAllPromotions = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.get(process.env.REACT_APP_API_BACKEND_DW + "/all", config);
};
