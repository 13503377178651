import { useFormik } from "formik";
import * as React from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useFetch } from "../../../Hooks/useFetch";
import {
  getUserByToken,
  setPasswordNewUser,
} from "../../../service/UserService/UserService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import * as yup from "yup";
import "./NewUser.css";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import userEvent from "@testing-library/user-event";
import { toast } from "react-toastify";
function NewUserExternal(props: any) {
  const { token }: any = useParams();

  const [user, isLoading, updateUser] = useFetch(getUserByToken, token);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  if (!user) {
    return <Redirect to="/login"></Redirect>;
  }
  return (
    <div className="new_user_container">
      <div className="box_new_user">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <img
            style={{ height: "100px" }}
            src={"/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"}
          ></img>{" "}
          <h2
            style={{
              fontFamily: "var(--lis-title)",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Applicación de wayfinding
          </h2>
          <h5 style={{ fontFamily: "var(--lis-title)", textAlign: "center" }}>
            Hola!{" "}
            <a
              style={{
                fontFamily: "var(--lis-title)",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {user.display_name}
            </a>{" "}
            cree su contraseña para entrar en la aplicación.
          </h5>
          <FormNewUserInternal user={user} />
        </div>
      </div>
    </div>
  );
}

export default NewUserExternal;

const validationSchema = yup.object({
  password: yup
    .string()
    .min(10, "La contraseña debe tener al menos 10 letras")
    .required("La contraseña es requerida")
    .test(
      "isValidPass",
      "La contraseña debe tener al menos una simbolo, un número y una letra en mayusculas",
      (value: any, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbole = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben de ser iguales"),
});

const FormNewUserInternal = (props: any) => {
  const history = useHistory();
  const { user } = props;
  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const value = {
        id_user: user.id,
        password: values.password,
      };

      setPasswordNewUser(value).then(() => {
        toast.success("Se ha completado el registro");
        history.push("/login");
      });
    },
  });
  return (
    <form className="w-100" onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div className="w-100 p-4">
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          ></TextField>
        </div>
        <div className="w-100 p-4">
          <TextField
            type="password"
            fullWidth
            id="repeatPassword"
            name="repeatPassword"
            label="Repetir contraseña"
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.repeatPassword &&
              Boolean(formik.errors.repeatPassword)
            }
            helperText={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
          ></TextField>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit" className="btn_submit">
          Crear usuario
        </Button>
      </div>
    </form>
  );
};
