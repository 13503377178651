import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  TextField,
  FormControl,
  Checkbox,
} from "@mui/material";

import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import { useFormik } from "formik";
import { useFetch, useIsLoading } from "../../../../../Hooks/useFetch";
import { getAllFormat } from "../../../../../service/FormatService/FormatService";
import { getAllDelegations } from "../../../../../service/DelegationServices/DelegationServices";
import { getAllPromotions } from "../../../../../service/DW_Promotions/DW_Promotions_Service";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import { getFilterOptions } from "../../../../../service/FilterService/FilterService";
// import {  getDraftOrgTags,
//           getCurrentOrgTags,
//           getDraftOrgDepartment,
//           getCurrentOrgDepartment } from '../../../../../Services/OrganizationChartService/OrganizationChartService'

function FilterForm(props: any) {
  const history = useHistory();
  const { tags, departments, setDraft } = props;
  const [isLoadingOptions, setisLoadingOptions] = useState(true);
  const [types, isFetchingTypes] = useFetch(getAllFormat);

  const [options, setOptions] = useState<any>();

  const soldStatusOptions = [
    {
      id: 1,
      name: "En comercialización",
    },
    {
      id: 3,
      name: "Totalmente entregadas",
    },
    {
      id: 2,
      name: "Totalmente vendidas",
    },
  ];

  useEffect(() => {
    if (isLoadingOptions) {
      setisLoadingOptions(true);
      getFilterOptions({
        format: [],
        dt: [],
        proms: [],
      })
        .then((response: any) => {
          props.setFilterOptions(response.data);
          setOptions(response.data);
        })
        .finally(() => setisLoadingOptions(false));
    }
  }, []);

  const [dt, isFetchingDT] = useFetch(getAllDelegations);
  const [promotions, isFetchingPromotions] = useFetch(getAllPromotions);

  const isLoading = useIsLoading([
    isFetchingPromotions,
    isFetchingDT,
    isFetchingTypes,
    isLoadingOptions,
  ]);

  const sorterFunction = (a: any, b: any) => {
    return a.name?.localeCompare(b.name, "es");
  };

  const params: any = useParams();

  // console.log("filters", props.filters);
  const formik = useFormik({
    initialValues: {
      promotion: props.filters ? props.filters.promotion : [],
      dt: props.filters ? props.filters.dt : [],
      type: props.filters ? props.filters.type : [],
      sold: props.filters ? props.filters.sold : [],
      incidence: props.filters ? props.filters.incidence : false,
    },

    validationSchema: null,

    onSubmit: (values) => {
      props.handleFilter(values);
      props.handleOpenFilterForm();
      console.log(values);
    },
  });

  useEffect(() => {
    getFilterOptions({
      format: formik.values.type.map((pro: any) => pro.id),
      dt: formik.values.dt.map((pro: any) => pro.id),
      proms: formik.values.promotion.map((pro: any) => pro.id),
    }).then((response: any) => {
      response.data.dt?.sort(sorterFunction);
      response.data.format?.sort(sorterFunction);
      response.data.proms?.sort(sorterFunction);
      props.setFilterOptions(response.data);
      setOptions(response.data);
    });
    console.log(formik.values);
  }, [formik.values]);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  // console.log(promotions, dt, types);

  return (
    <>
      <h2 className='filter-title'>Filtros</h2>
      <form onSubmit={(values) => formik.handleSubmit(values)}>
        <Autocomplete
          id='type'
          className='filter-input'
          multiple
          options={options?.format?.sort(sorterFunction) ?? []}
          getOptionLabel={(option: any) => option.name}
          onChange={(e, value) => {
            formik.setFieldValue("type", value);
          }}
          // @ts-ignore
          value={formik.values.type}
          renderInput={(params) => (
            <TextField {...params} label='Formato' size='medium' />
          )}
        />
        <Autocomplete
          id='dt'
          className='filter-input'
          multiple
          options={options?.dt?.sort(sorterFunction) ?? []}
          onChange={(e, value) => {
            formik.setFieldValue("dt", value);
          }}
          // @ts-ignore
          value={formik.values.dt}
          // onChange={(e, value) => {
          //   formik.setFieldValue("tag", value);
          // }}
          getOptionLabel={(option: any) => option.name}
          // isOptionEqualToValue={(option: any, value: any) =>
          //   option.id === value.id
          // }
          // disabled={formik.values.department ? true : false}
          // defaultValue={formik.values.tag}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Dirección Territorial'
              size='medium'
            />
          )}
        />

        <Autocomplete
          id='promotion'
          className='filter-input'
          multiple
          options={options?.proms?.sort(sorterFunction) ?? []}
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          onChange={(e, value) => {
            formik.setFieldValue("promotion", value);
          }}
          // @ts-ignore
          value={formik.values.promotion}
          renderInput={(params) => (
            <TextField {...params} label='Promoción' size='medium' />
          )}
        />

        <Autocomplete
          id='sold'
          className='filter-input'
          multiple
          options={soldStatusOptions ?? []}
          // options={options.status} Depends on key name
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          onChange={(e, value) => {
            formik.setFieldValue("sold", value);
          }}
          // @ts-ignore
          value={formik.values.sold}
          renderInput={(params) => (
            <TextField {...params} label='Estado promoción' size='medium' />
          )}
        />

        <FormGroup>
          <FormControlLabel
            value={formik.values.incidence}
            control={
              <Checkbox
                checked={formik.values.incidence}
                color='success'
                onClick={() =>
                  formik.setFieldValue("incidence", !formik.values.incidence)
                }
              />
            }
            label='WF con Incidencias Abiertas'
            style={{ marginBottom: "1rem" }}
          />
        </FormGroup>

        <div className='filter-button-container'>
          <Button
            // loading={IsLoadingButtom}
            onClick={() => {
              props.handleOpenFilterForm();
              props.handleFilterReset();
            }}
            className='filter-button'
            style={{
              backgroundColor: "#b1003b",
            }}
            fullWidth
          >
            Borrar filtros
          </Button>
          <LoadingButton
            className='filter-button'
            style={{
              color: "var(--blue-lis)",
              backgroundColor: "var(--blue-lis)",
            }}
            fullWidth
            type='submit'
          >
            Aplicar
          </LoadingButton>
        </div>
      </form>
    </>
  );
}

export default FilterForm;
