import React, { useState, useCallback, useEffect, useRef } from "react";
import Map, { MapRef, Marker, MarkerDragEvent, ViewStateChangeEvent } from 'react-map-gl';

import "mapbox-gl/dist/mapbox-gl.css";

export const MapEditCood = (props: any) => {
  const mapRef = useRef<MapRef | null >(null);

  const { formik } = props;
  const [ longitude, latitude ] = props.lngLat;
  const [viewState, setViewState] = useState({
    longitude,
    latitude,
    zoom: 14
  });

  const [marker, setMarker] = useState<any>({
    longitude,
    latitude,
  });

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setMarker({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  useEffect(() => {
    formik.setFieldValue("latitude", marker.latitude);
    formik.setFieldValue("longitude", marker.longitude);

    props.setNewCoords([marker.longitude, marker.latitude]);
  }, [marker]);

  return (
    <Map 
        {...viewState}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        style={{width: 'calc(100% - 20px)',minHeight: '60vh', margin: "10px"}}
        onMove={evt => setViewState(evt.viewState)}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN || ""}
    >
      <Marker 
        longitude={marker.longitude} 
        latitude={marker.latitude} 
        anchor="bottom" 
        draggable={true} 
        color="#092e3b"
        onDrag={onMarkerDrag}
        >
        <img src={"/Assets/img/icons/location_on_black.png"} />
      </Marker>

    </Map>
  );
};
