import React, { useState, useEffect } from "react";
import moment from "moment";

import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Shared/ModalCustom/CustomModal";
import { WayfindingCard } from "../Map/WayfindingCard/WayfindingCard";
import { NewIncidenceModal } from "../Map/NewIncidenceModal/NewIncidenceModal";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import {
  getAllWayfindings,
  getWayfindingAndPromotionsByIdUser,
} from "../../../service/WayfindingServices/WayfindingService";
import { getAllFormat } from "../../../service/FormatService/FormatService";
import { getAllUsers } from "../../../service/UserService/UserService";
import { getAllPromotions } from "../../../service/DW_Promotions/DW_Promotions_Service";
import { getDBPromotions } from "../../../service/PromotionServices/PromotionServices";
import { getAllDelegations } from "../../../service/DelegationServices/DelegationServices";

import "./WayfindingList.css";
import { useSelector } from "react-redux";

export const WayfindingList = () => {
  const [isLoadingProcessData, setisLoadingProcessData] = useState(true);
  const user = useSelector((state: any) => state.userReducer.user.user);

  const [PromosAndWayfindings, isFetchingPromosAndWayfindings, updateDataWF] =
    useFetch(getWayfindingAndPromotionsByIdUser, user.id);

  // const [formats, isFetchingFormats] = useFetch(getAllFormat);
  const [promotions, isLoadingPro] = useFetch(getAllPromotions);
  const [formats, isLoadingFormats] = useFetch(getAllFormat);
  const [users, isLoadingUsers] = useFetch(getAllUsers);

  const isLoading = useIsLoading([
    isLoadingPro,
    isLoadingFormats,
    isLoadingUsers,
    isFetchingPromosAndWayfindings,
  ]);
  // const [promotions, isFetchingPromotions] = useFetch(getDBPromotions);

  // const [delegations, isFetchingDelegations] = useFetch(getAllDelegations);

  const [openEditModalShow, setOpenEditModalShow] = useState<boolean>(false);
  const [openNewIncidence, setOpenNewIncidence] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedFormat, setSelectedFormat] = useState<any>(null);

  const [RowsData, setRowsData] = useState([]);

  const processData = () => {
    let auxArray: any = PromosAndWayfindings.wayfindings.map((way: any) => {
      const creator: any = users?.find((user: any) => user.id === way.id_user);
      const editor: any = users?.find(
        (user: any) => user.id === way.last_mod_user
      );
      const format: any = formats?.find(
        (format: any) => format.id === way.id_format
      );
      const promotion: any = promotions?.find(
        (promotion: any) => promotion.id === way.id_promotion
      );

      return {
        id: way.id,
        created_at: moment(way.created_at).format("YYYY/MM/DD HH:mm"),
        user: creator ? creator.display_name : "",
        id_user: way.id_user,
        updated_at:
          way.updated_at !== null
            ? moment(way.updated_at).format("YYYY/MM/DD HH:mm")
            : moment(way.created_at).format("YYYY/MM/DD HH:mm"),
        last_mod_user: editor ? editor.display_name : "-",
        format: format ? format.name : "",
        id_format: way.id_format,
        circlet: way.circlet,
        promotion: way.promotion_name,
        incidences: way.incidences,
        id_promotion: way.id_promotion,
        territorial_name: way.territorial_name,
        monthly_price: way.monthly_price ? way.monthly_price + "€" : "-",
        start_contact_date: way.start_contact_date,
        finish_contact_date: way.finish_contact_date,
        comments: way.comments,
        ph_number: way.ph_number ?? "Sin imágenes",
      };
    });
    setRowsData(auxArray);
    setisLoadingProcessData(false);
  };
  useEffect(() => {
    if (!isLoading) {
      processData();
    } else {
      setisLoadingProcessData(true);
    }
  }, [isLoading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
    },
    {
      field: "created_at",
      headerName: "Fecha de creación",
      type: "string",

      width: 50,
      flex: 1,
    },
    {
      field: "id_user",
      headerName: "Usuario creación",
      type: "string",
      width: 200,
      hide: true,
      flex: 1,
    },
    {
      field: "user",
      headerName: "Usuario creación",
      type: "string",
      width: 200,
      hide: true,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Última actualización",
      type: "string",
      width: 50,
      flex: 1,
    },
    {
      field: "last_mod_user",
      headerName: "Usuario modificación",
      type: "string",

      width: 200,
      hide: true,
      flex: 1,
    },
    {
      field: "promotion",
      headerName: "Promoción",
      type: "string",
      width: 200,
      flex: 1,
    },

    {
      field: "territorial_name",
      headerName: "Dirección Territorial",
      type: "string",
      width: 200,
      flex: 1,
    },
    {
      field: "format",
      headerName: "Formato",
      type: "string",
      width: 50,
      flex: 1,
    },
    {
      field: "id_format",
      headerName: "id Formato",
      type: "string",
      hide: true,
      width: 50,
      flex: 1,
    },
    {
      field: "monthly_price",
      headerName: "Precio mensual",
      type: "string",
      width: 50,
      flex: 1,
    },
    {
      field: "incidences",
      headerName: "Nº incidencias",
      width: 50,
      flex: 1,
    },
    {
      field: "ph_number",
      headerName: "Nº imágenes",
      type: "string",
      width: 50,
      flex: 1,
    },
    {
      field: "circlet",
      headerName: "Anillo",
      type: "string",
      width: 50,
      flex: 1,
    },
  ];

  const onCellClick = (event: any) => {
    setSelectedRow(event.row);
    setOpenEditModalShow(!openEditModalShow);
    console.log(selectedRow);
  };

  const handleClickNewInc = () => {
    setOpenNewIncidence(!openNewIncidence);
    setOpenEditModalShow(!openEditModalShow);
  };

  if (isLoadingProcessData || isLoading) {
    return (
      <>
        <AedasLoading></AedasLoading>
      </>
    );
  }

  return (
    <div>
      <h2 className='page-title'>Listado de Wayfindings</h2>

      <DataGridEditabled
        showHeader={false}
        rows={RowsData ?? []}
        columns={columns}
        onCellClick={(event: any) => onCellClick(event)}
        exportIcon={true}
        disableColumnSelector
      ></DataGridEditabled>

      <CustomModal
        size='xl'
        show={openEditModalShow}
        onChange={() => {
          setOpenEditModalShow(!openEditModalShow);
        }}
        title='Ficha'
        body={
          <WayfindingCard
            show={openEditModalShow}
            selectedRow={selectedRow}
            setIsLoading={() => {
              updateDataWF();
            }}
            handleSelfShow={setOpenEditModalShow}
            newIncidence={() => {
              handleClickNewInc();
            }}
          />
        }
      />

      <CustomModal
        size='xl'
        show={openNewIncidence}
        onChange={() => {
          setOpenNewIncidence(!openNewIncidence);
        }}
        title='Nueva Incidencia'
        body={
          <NewIncidenceModal
            setOpenNewIncidence={setOpenNewIncidence}
            selectedRow={selectedRow}
            selectedFormat={selectedFormat?.name}
            formats={formats}
            // openDetails={() => {
            //   handleClickIncDetails();
            // }}
          />
        }
      />
    </div>
  );
};
