import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const login = async (email: string, password: string) => {
  const response: any = await axios
    .post(process.env.REACT_APP_API_BACKEND + "/external_user" + "/login/", {
      user: email,
      password: password,
    })
    .catch((error) => {
      if (error.request.status === 401) {
        toast.error("Usuario o contraseña incorrecto");
      } else {
        toast.error("Usuario o contraseña incorrecto");
      }
    });

  if (!response) {
    return null;
  }

  localStorage.setItem("aedas_user", JSON.stringify(response.data));
  return JSON.stringify(response.data);
};

export const loginAzure = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/auth" + "/loginAzure",
    config
  );
};

export const getUserPhoto = async (user_id_azure: string) => {
  //ultiliza el id_azure
  return await axios.get(
    process.env.REACT_APP_INTEGRA_BACKEND + "/photos/" + user_id_azure,
    {
      headers: { 
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
        }` 
      },
      responseType: "blob"
    }
  );
};
