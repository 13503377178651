import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import CustomModal from "../../../Shared/ModalCustom/CustomModal";
import { IncidenceDetail } from "../IncidenceDetail/IncidenceDetail";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";

import { getSectorById } from "../../../../service/SectorService/SectorService";
import { getDelegationById } from "../../../../service/DelegationServices/DelegationServices";
import { getAllPromotions } from "../../../../service/DW_Promotions/DW_Promotions_Service";
import { getDBPromotions } from "../../../../service/PromotionServices/PromotionServices";

import {
  deleteWayfindingById,
  getWayfindingPhotos,
} from "../../../../service/WayfindingServices/WayfindingService";
import {
  getIncidences,
  getIncByWFId,
} from "../../../../service/IncidenceServices/IncidenceService";

import "./WayfindingCard.css";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { checkCanView } from "../../../../Utils/checkCanView";

export const WayfindingCard = (props: any) => {
  // const [sector, isFetchingSector] = useFetch(
  //   getSectorById,
  //   props.selectedRow.id_sector
  // );
  const [delegation, isFetchingDelegation] = useFetch(
    getDelegationById,
    props.selectedRow.id_territorial_delegation
  );
  const user = useSelector((state: any) => state.userReducer.user.user);

  const [promotionsDWH, isFetchingPromotions] = useFetch(getAllPromotions);
  const [promotions, isFetchingPromos] = useFetch(getDBPromotions);
  const [incidences, isFetchingIncidences] = useFetch(getIncidences);
  const [wfPromotion, setWfPromotion] = useState(null);
  const [wfIncidences, setWfIncidences] = useState(null);

  const [photos, isFetchingPhotos] = useFetch(
    getWayfindingPhotos,
    props.selectedRow.id
  );
  const isLoading = useIsLoading([
    // isFetchingSector,
    isFetchingDelegation,
    isFetchingPromotions,
    isFetchingPromos,
    isFetchingIncidences,
    isFetchingPhotos,
  ]);

  const history = useHistory();

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const handleClickOpenDeleteAlert = () => setOpenDeleteAlert(true);
  const handleCloseDeleteAlert = () => setOpenDeleteAlert(false);

  const handleRemove = () => {
    handleCloseDeleteAlert();

    deleteWayfindingById(props.selectedRow.id)
      .then(() => {})
      .finally(() => {
        props.handleSelfShow();
        props.setIsLoading(true);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      // @ts-ignore: Unreachable code error
      const proyecto_prinex = promotions.find(
        (promotion: any) => promotion.id === props.selectedRow.id_promotion
      );

      // @ts-ignore: Unreachable code error
      setWfPromotion(
        promotionsDWH?.find(
          (promotion: any) => promotion.proyecto_prinex === proyecto_prinex.name
        )
      );

      // @ts-ignore: Unreachable code error
      const incidenceByWf = incidences.filter(
        (incidence: any) => incidence.id_wayfinding === props.selectedRow.id
      );
      setWfIncidences(incidenceByWf);
    }
  }, [props.selectedRow, isLoading]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      {props.show && !isLoading ? (
        <Box component="form">
          <div className="card-container">
            <div className="img-icon-box">
              <div className="image-container">
                <Carousel
                  statusFormatter={(currentItem, total) =>
                    `${currentItem} de ${total}`
                  }
                >
                  {photos.photos.length < 1 ? (
                    <div className="default-image-container">
                      <img
                        className="default-image"
                        src={"/Assets/img/default.jpg"}
                      />
                    </div>
                  ) : (
                    photos?.photos?.map((photo: any) => (
                      <div style={{ maxHeight: "500px" }}>
                        <img
                          style={{ maxHeight: "500px", objectFit: "contain" }}
                          src={"data:image/jpeg;base64," + photo.photos}
                        />
                      </div>
                    ))                  
                  )}
                </Carousel>
              </div>
              {checkCanView(
                user,
                props.selectedRow.id_user,
                props.selectedRow.id_territorial_delegation
              ) ? (
                <div className="header-icons">
                  <Tooltip title="Editar Wayfinding" placement="right-start">
                    <CreateRoundedIcon
                      style={{ backgroundColor: "#092e3b" }}
                      onClick={() =>
                        history.push(
                          `/editar-wayfinding/${props.selectedRow.id}`
                        )
                      }
                    />
                  </Tooltip>

                  <Tooltip title="Reportar incidencia" placement="right-start">
                    <ReportRoundedIcon
                      style={{ backgroundColor: "#b1a200" }}
                      onClick={() => props.newIncidence()}
                    />
                  </Tooltip>

                  {
                    // @ts-ignore: Unreachable code error
                    wfIncidences && wfIncidences.length > 0 ? (
                      <Tooltip
                        title="Ver listado de incidencias"
                        placement="right-start"
                      >
                        <FormatListBulletedRoundedIcon
                          style={{ backgroundColor: "#b1a200" }}
                          onClick={() =>
                            history.push(
                              `/lista-incidencias/${props.selectedRow.id}`
                            )
                          }
                        />
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }

                  <Tooltip title="Borrar Wayfinding" placement="right-start">
                    <DeleteRoundedIcon
                      style={{ backgroundColor: "#b1003b" }}
                      onClick={() => handleClickOpenDeleteAlert()}
                    />
                  </Tooltip>

                  <Dialog
                    open={openDeleteAlert}
                    onClose={handleCloseDeleteAlert}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"¿Deseas eliminar este Wayfinding?"}
                    </DialogTitle>
                    <DialogActions className="buttons-container">
                      <Button
                        className="no_button"
                        onClick={handleCloseDeleteAlert}
                      >
                        No
                      </Button>
                      <Button
                        className="yes_button"
                        onClick={() => handleRemove()}
                        autoFocus
                      >
                        Si
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="data-text">
              <TextField
                id="format"
                label="Formato"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={props.selectedFormat || props.selectedRow.format }
              />

              {delegation ? (
                <TextField
                  id="delegation"
                  label="Dirección Territorial"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  // @ts-ignore: Unreachable code error
                  value={delegation?.name || props.selectedRow.territorial_name }
                />
              ) : (
                <></>
              )}

              <TextField
                id="circlet"
                label="Anillo"
                variant="standard"
                value={props.selectedRow.circlet}
              />

              {wfPromotion ? (
                <TextField
                  id="promotion"
                  label="Promoción"
                  variant="standard"
                  // @ts-ignore: Unreachable code error
                  value={wfPromotion.proyecto_prinex}
                />
              ) : (
                <></>
              )}
              {/* {sector ? (
                <TextField
                  id="sector"
                  label="Sector"
                  variant="standard"
                  // @ts-ignore: Unreachable code error
                  value={sector?.name}
                />
              ) : (
                <></>
              )} */}
              {checkCanView(
                user,
                props.selectedRow.id_user,
                props.selectedRow.id_territorial_delegation
              ) ? (
                <>
                  {" "}
                  <TextField
                    id="montly-price"
                    label="Precio Mensual"
                    variant="standard"
                    value={
                      props.selectedRow.monthly_price === null
                        ? "-"
                        : `${props.selectedRow.monthly_price}`
                    }
                  />
                  {wfIncidences ? (
                    <TextField
                      id="incidence"
                      label="Incidencias"
                      variant="standard"
                      // @ts-ignore: Unreachable code error
                      value={wfIncidences.length > 0 ? "Si" : "No"}
                      InputProps={
                        // @ts-ignore: Unreachable code error
                        wfIncidences.length > 0 ? (
                          {
                            endAdornment: (
                              <InputAdornment position="end">
                                <RemoveRedEyeIcon
                                  onClick={() =>
                                    history.push(
                                      `/lista-incidencias/${props.selectedRow.id}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </InputAdornment>
                            ),
                          }
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  <TextField
                    id="start-contract"
                    label="Inicio contrato"
                    variant="standard"
                    value={
                      props.selectedRow.start_contact_date === null
                        ? "-"
                        : moment(props.selectedRow.start_contact_date).format(
                            "DD/MM/YYYY"
                          )
                    }
                  />
                  <TextField
                    id="end-contract"
                    label="Fin Contrato"
                    variant="standard"
                    value={
                      props.selectedRow.finish_contact_date === null
                        ? "-"
                        : moment(props.selectedRow.finish_contact_date).format(
                            "DD/MM/YYYY"
                          )
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            {props.selectedRow.comments ? (
              <div className="notes-box">
                <span className="css-1c2i806-MuiFormLabel-root-MuiInputLabel-root">
                  Anotaciones
                </span>
                <p> {props.selectedRow.comments} </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
