import * as React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

import { userLogin } from "../../../Redux/User/UserAction";
import { login } from "../../../service/AuthService/AuthService";
import "./Login.css";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Introduzca un email valido. Ej: ejemplo@gmail.com")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe ser de al menos 8 caracteres")
    .required("La contraseña es requerida"),
});

export default function LoginForm(props: any) {
  const history = useHistory();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: null,
    onSubmit: async (values) => {
      login(values.email, values.password).then((user: any) => {
        dispatch(userLogin(user, user.token, ""));
        window.location.href = process.env.REACT_APP_BASE_REDIRECT + "/mapa";
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-2">
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          ></TextField>
        </div>
        <div className="p-2">
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          ></TextField>
        </div>
        <div className="p-2">
          <LoadingButton
            color="primary"
            style={{
              backgroundColor: "var(--blue-lis)",
              fontFamily: "var(--lis-title)",
            }}
            variant="contained"
            fullWidth
            loadingIndicator={<CircularProgress size={16} />}
            loading={isLoading}
            type="submit"
          >
            Entrar
          </LoadingButton>
        </div>
        <div className="p-2">
          <Button
            color="primary"
            style={{
              backgroundColor: "var(--blue-lis)",
              fontFamily: "var(--lis-title)",
              color: "white",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              instance
                .loginRedirect(loginRequest)
                .then((response) => {
                  history.push("/mapa");
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          >
            Entrar con cuenta AEDAS HOMES
          </Button>
        </div>
        <div className="p-2">
          <a style={{ fontFamily: "var(--lis-title)", fontSize: "10px" }}>
            Para recuperar la contraseña de un usuario externo, pulsa{" "}
            <span
              onClick={() => history.push("/recuperar-contrasenha")}
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              aqui
            </span>
            .
          </a>
        </div>
      </form>
    </>
  );
}
