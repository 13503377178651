import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";

import { TextField, MenuItem, Autocomplete, Select } from "@mui/material";
import * as yup from "yup";

import "./NewPartnerModal.css";
import { useFormik } from "formik";
import {
  addNewInternalUser,
  addNewExternalUser,
  getAllRols,
  getAllUsersEmails,
} from "../../../../service/UserService/UserService";
import { useFetch } from "../../../../Hooks/useFetch";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import { getAllDelegations } from "../../../../service/DelegationServices/DelegationServices";

export const NewPartnerModal = (props: any) => {
  const [rols, rolsIsfeching, updateRols] = useFetch(getAllRols);
  const [dts, dtsIsfeching, updateDT] = useFetch(getAllDelegations);

  const { setOpenEditModalShow } = props;
  const [selected, setSelected] = useState<any>(null);

  const handleSelect = (value: Number) => {
    console.log("entro");
    setSelected(value);
  };
  if (rolsIsfeching && dtsIsfeching) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <UserTypeSelect
      dts={dts}
      promotions={props.promotions}
      selected={selected}
      rolOptions={rols}
      handleSelect={handleSelect}
      setOpenEditModalShow={setOpenEditModalShow}
    ></UserTypeSelect>
  );
};

const UserTypeSelect = (props: any) => {
  const { handleSelect, selected, setOpenEditModalShow, dts } = props;
  switch (selected) {
    case 0:
      return (
        <FormNewUserInternal
          dts={dts}
          rolOptions={props.rolOptions}
          setOpenEditModalShow={setOpenEditModalShow}
        ></FormNewUserInternal>
      );
    case 1:
      return (
        <FormNewUserExternal
          promotions={props.promotions}
          rolOptions={props.rolOptions.filter(
            (r: any) => r.id === 3 || r.id === 4
          )}
          setOpenEditModalShow={setOpenEditModalShow}
        ></FormNewUserExternal>
      );

    default:
      return (
        <div className="button_group_new_user">
          <Button onClick={() => handleSelect(0)} className="btn_new_user">
            Usuario Interno
          </Button>
          <Button onClick={() => handleSelect(1)} className="btn_new_user">
            Usuario Externo
          </Button>
        </div>
      );
  }
};

const validationSchemaInternalUser = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("El email es requerido"),
  rol: yup.object().required("El rol es requerido"),
});

const FormNewUserInternal = (props: any) => {
  const { rolOptions, setOpenEditModalShow, dts } = props;
  const [userEmails, setUserEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      email: null,
      rol: null,
      dt: null,
    },
    validationSchema: validationSchemaInternalUser,
    onSubmit: (values: any) => {
      const value = {
        email: values.email,
        id_rol: values.rol.id,
        id_dt:
          values.rol.id == 2
            ? values.dt.map((dt: any) => dt.idDelegacion)
            : null,
        active: true,
      };

      addNewInternalUser(value)
        .then(() => {
          toast.success("Usuario añadido");
          setOpenEditModalShow(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    },
  });
  useEffect(() => {
    getAllUsersEmails()
      .then((response) => response.data)
      .then((data) => {
        console.log(data);
        setUserEmails(data);
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div className="w-50 p-4">
          <Autocomplete
            className="w-full"
            disablePortal
            id="email"
            /* A la vez que se guarda la opción escogida en el formik.values, también se guarda en la variable de estado */
            onChange={(event, value) => {
              //@ts-ignore
              formik.setFieldValue("email", value);
              console.log(value);
            }}
            /* Pinta el array que generamos con un fetch a la base de datos como las diferentes opciones del select */
            options={userEmails}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email"
                required
                variant="outlined"
                style={{ width: "100%" }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                InputLabelProps={{ shrink: true }}
              />
            )}
          ></Autocomplete>
        </div>
        <div className="w-50 p-4">
          <Autocomplete
            id="rol"
            options={rolOptions}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>

        {
          //@ts-ignore
          formik.values?.rol?.id === 2 ? (
            <div className="w-100 p-4">
              <Autocomplete
                id="dt"
                options={dts}
                onChange={(e, value) => {
                  formik.setFieldValue("dt", value);
                }}
                multiple
                getOptionLabel={(option: any) => option.CodDelegacionPromociona}
                isOptionEqualToValue={(option, value) =>
                  option.idDelegacion === value.idDelegacion
                }
                renderInput={(params) => (
                  <TextField name="dt" {...params} label="Delegaciónes" />
                )}
              />
            </div>
          ) : (
            <></>
          )
        }
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit" className="btn_submit">
          Crear usuario
        </Button>
      </div>
    </form>
  );
};

const validationSchemaExternal = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("El email es requerido"),
  rol: yup.object().required("El rol es requerido"),
  surname: yup.string().required("El apellido es requerido"),
  name: yup.string().required("El nombre es requerido"),
  promotions: yup.array().required("Debe tener almenos una promoción"),
});

const FormNewUserExternal = (props: any) => {
  const { rolOptions, setOpenEditModalShow } = props;
  const formik = useFormik({
    initialValues: {
      email: null,
      rol: null,
      surname: null,
      name: null,
      promotions: null,
    },
    validationSchema: validationSchemaExternal,
    onSubmit: (values: any) => {
      const value = {
        email: values.email,
        id_rol: values.rol.id,
        name: values.name,
        surnames: values.surname,
        promotion: values.promotions.map((pro: any) => pro.id),
      };
      console.log(value);
      addNewExternalUser(value)
        .then(() => {
          toast.success("Usuario añadido");
          setOpenEditModalShow(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div className="w-50 p-4">
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          ></TextField>
        </div>
        <div className="w-50 p-4">
          <TextField
            fullWidth
            id="surname"
            name="surname"
            label="Apellidos"
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
          ></TextField>
        </div>
        <div className="w-50 p-4">
          <TextField
            id="email"
            className="w-full"
            label="Email"
            required
            variant="outlined"
            style={{ width: "100%" }}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="w-50 p-4">
          <Autocomplete
            id="rol"
            options={rolOptions}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>
        <div className="w-100 p-4">
          <Autocomplete
            id="promotions"
            options={props.promotions}
            onChange={(e, value) => {
              formik.setFieldValue("promotions", value);
            }}
            multiple
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="promotions" {...params} label="Promociones" />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button type="submit" className="btn_submit">
          Crear usuario
        </Button>
      </div>
    </form>
  );
};
