import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import "./Login.css";
import { Redirect, useHistory } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../../authConfig";
import { loginAzure } from "../../../service/AuthService/AuthService";
import { userLogin } from "../../../Redux/User/UserAction";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import { height } from "@mui/system";

function Login(props: any) {
  const history = useHistory();

  return (
    <div>
      <div className="backgraundLogin">
        <div className="boxLogin row">
          <div
            style={{ height: "70px" }}
            className="row d-flex justify-content-center"
          >
            <img
              className="col-12 p-4 m-4"
              src="/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"
              style={{ width: "150px" }}
            ></img>
            <div className=" col-12 d-flex">
              <h1 className="appNameLogin">Wayfinding</h1>
            </div>
            <div></div>
            <div>
              <div className="buttonLogin">
                <LoginForm setisLogin={props.setisLogin}></LoginForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="row login">
      <div
        className="d-none d-lg-block col-8"
        style={{ backgroundColor: "var(--primary-color)" }}
      >
        <div className="d-flex align-items-center h-100">
          <img
            style={{ width: "100%" }}
            src={"/Assets/img/promocion.jpg"}
          ></img>
        </div>
      </div>
      <div className="col-12 col-lg-4 loginContainer row p-4">
        <div>
          <div className="d-flex justify-content-around">
            <img
              className="col-12"
              style={{ height: "80px", width: "160px", marginBottom: "20px" }}
              src={"/Assets/img/logoAedas.svg"}
            ></img>
          </div>

          <LoginForm setisLogin={props.setisLogin}></LoginForm>
        </div>
      </div>
    </div>
  );
}

export default Login;
