import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import CustomModal from "Components/Shared/ModalCustom/CustomModal";
import { WayfindingCard } from "../WayfindingCard/WayfindingCard";
import { IncidenceDetail } from "../IncidenceDetail/IncidenceDetail";
import { NewIncidenceModal } from "../NewIncidenceModal/NewIncidenceModal";

export const MapModals: FC<any> = (props: any) => {
  const { openEditModalShow, 
          setOpenEditModalShow, 
          selectedRow, 
          wfSelectedFormat, 
          updateData, 
          formats } = props;
  const [openIncidenceDetails, setOpenIncidenceDetails] = useState<boolean>(false);
  const [openNewIncidence, setOpenNewIncidence] = useState<boolean>(false);
  
  const handleSelfShow = () => {
    setOpenEditModalShow(!openEditModalShow);
  };

  const handleClickNewInc = () => {
    setOpenNewIncidence(!openNewIncidence);
    setOpenEditModalShow(!openEditModalShow);
  };


  return (
    <>
       <CustomModal
            size="xl"
            show={openEditModalShow}
            onChange={() => {
              setOpenEditModalShow(!openEditModalShow);
            }}
            title="Ficha"
            body={
              <WayfindingCard
                show={openEditModalShow}
                selectedRow={selectedRow}
                handleSelfShow={handleSelfShow}
                // setIsLoading={() => setflag(!flag)}
                selectedFormat={wfSelectedFormat}
                newIncidence={() => {
                  handleClickNewInc();
                }}
              />
            }
          />

          <CustomModal
            size="xl"
            show={openIncidenceDetails}
            onChange={() => {
              setOpenIncidenceDetails(!openIncidenceDetails);
            }}
            title="Ver Incidencia"
            body={<IncidenceDetail />}
          />

          <CustomModal
            size="xl"
            show={openNewIncidence}
            onChange={() => {
              setOpenNewIncidence(!openNewIncidence);
            }}
            title="Nueva Incidencia"
            body={
              <NewIncidenceModal
                setOpenNewIncidence={setOpenNewIncidence}
                selectedRow={selectedRow}
                selectedFormat={wfSelectedFormat}
                formats={formats}
                updateData={updateData}
              />
            }
          />
    </>
  )
}