import React from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

import "./IncidenceDetail.css";

export const IncidenceDetail = (props: any) => {
  const { incidence, type } = props;

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ textAlign: "center" }}
    >
      <div className="indidence-detail card-container">
        <div className="img-icon-box">
          <img
            src={
              incidence.photos[0]
                ? "data:image/jpeg;base64," + incidence.photos[0]
                : "/Assets/img/default.jpg"
            }
            alt=""
            style={{
              width: "80%",
              margin: "0 auto",
              maxHeight: "380px",
              objectFit: "contain",
            }}
          />
        </div>

        <div className="notes-box">
          <span
            style={{ textDecoration: "", fontSize: "20px", fontWeight: "bold" }}
          >
            Anotaciones
          </span>
          <hr></hr>
          <p> {incidence.comment} </p>
        </div>
      </div>
    </Box>
  );
};
