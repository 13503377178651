import React, { useEffect, useState } from "react";

import {
  Route,
  useHistory,
  useLocation,
  Redirect,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { toast, ToastContainer } from "react-toastify";
import { IPublicClientApplication } from "@azure/msal-browser";

import { CustomNavigationClient } from "./Utils/NavigationClient";

import PlaceIcon from "@mui/icons-material/Place";
import GroupsIcon from "@mui/icons-material/Groups";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddIcon from "@mui/icons-material/Add";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import Header from "./Components/Shared/Header/Header";
import Login from "./Components/Static/Login/Login";
//import { Map } from './Components/Static/Map/Map';
import { Mapa } from "./Components/Static/Map/Mapa";
import { WayfindingList } from "./Components/Static/WayfindingList/WayfindingList";
import { EditWayfinding } from "./Components/Static/EditWayfinding/EditWayfinding";
import { IncidenceList } from "./Components/Static/IncidenceList/IncidenceList";
import { NewWayfinding } from "./Components/Static/NewWayfinding/NewWayfinding";
import { Partners } from "./Components/Static/Partners/Partners";
import { Statistics } from "./Components/Static/Statistics/Statistics";
import NewUserExternal from "./Components/Static/NewUser/NewUserExternal";
import { locale } from "moment";
import { loginRequest } from "./authConfig";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { validatePermission } from "./Utils/permissionsUtils";
import { loginAzure } from "./service/AuthService/AuthService";
import { userLogin } from "./Redux/User/UserAction";
import { isLoginJWT } from "./Utils/jwtUtils";
import AedasLoading from "./Components/Shared/AedasLoading/AedasLoading";
import RecoveryPassword from "./Components/Static/RecoveryPassword/RecoveryPassword";
import RecoveryPasswordToken from "./Components/Static/RecoveryPasswordToken/RecoveryPasswordToken";
import { MainMap } from "./Components/Static/Map/MainMap";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  // const isLogin = true;
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userReducer.user?.user);

  useEffect(() => {
    if (isLogin) {
      if (location.pathname === "/login") {
        window.location.href = process.env.REACT_APP_BASE_REDIRECT + "/mapa";
      } else {
        if (location.pathname === "/" || location.pathname === "") {
          history.push("/mapa");
        } else {
          history.push(location.pathname);
        }
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin) {
      if (isAuthenticated && inProgress === "none") {
        const request: any = {
          ...loginRequest,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(request)
          .then(async (response) => {
            const responseBackend: any = await loginAzure(
              response.accessToken
            ).catch((error) => console.log("ERROR"));

            let position: string = "Por defecto";

            if (responseBackend) {
              dispatch(
                userLogin(responseBackend.data, response.accessToken, position)
              );
              localStorage.setItem(
                "aedas_user",
                JSON.stringify(responseBackend.data)
              );
              history.push("/mapa");
            } else {
              // toast.error("No tienes permiso");
              history.push("/login");
              setIsLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (
        !isAuthenticated &&
        inProgress === "none" &&
        location.pathname !== "/login" &&
        location.pathname !== "/recuperar-contrasenha" &&
        location.pathname.includes("/new_user") &&
        location.pathname.includes("/cambiar_contrasenha")
      ) {
        instance
          .loginRedirect(loginRequest)
          .then((response) => {
            console.log("login", response);
          })
          .catch((e) => {
            console.error(e);
          });
      } else if (inProgress === "none") {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, inProgress, isLogin]);

  const menu: any = [
    {
      name: "Mapa",
      localPath: "/mapa",
      icon: <PlaceIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Mapa",
      hasPermissions: true,
    },
    {
      name: "Listado de Wayfindings",
      localPath: "/lista-wayfindings",
      icon: <ViewListIcon />,
      onClick: history.push,
      canView: validatePermission(user, [1, 2, 3]),
      shortName: "WFs",
      hasPermissions: true,
    },
    {
      name: "Nuevo Wayfinding",
      localPath: "/nuevo-wayfinding",
      icon: <ControlPointIcon />,
      onClick: history.push,
      shortName: "Nuevo WF",
      canView: validatePermission(user, [1, 2, 3]),
    },
    {
      name: "Colaboradores",
      localPath: "/colaboradores",
      icon: <GroupsIcon />,
      onClick: history.push,
      canView: validatePermission(user, [1]),
      shortName: "Colab.",
      hasPermissions: true,
    },
    // {
    //   name: "Estadísticas",
    //   localPath: "/estadisticas",
    //   icon: <LeaderboardIcon />,
    //   onClick: history.push,
    //   canView: validatePermission(user, [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]),
    //   shortName: "Estad.",
    //   hasPermissions: true,
    // },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <>
      <div style={{ height: "100vh", overflowX: "hidden" }}>
        {isLogin &&
        !location.pathname.includes("/new_user") &&
        location.pathname !== "/recuperar-contrasenha" &&
        !location.pathname.includes("/new_user") &&
        !location.pathname.includes("/cambiar_contrasenha") ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin && !location.pathname.includes("/new_user")
              ? {
                  flex: 1,
                  //cambio 300
                  marginLeft: isOpen ? "70px" : "70px",
                  maxWidth: "100vw",
                  // overflow: "hidden"
                }
              : {}
          }
          className="w-100"
        >
          <Switch>
            <Route exact path="/login">
              <Login isLogin={isLogin}></Login>
            </Route>

            <Route exact path="/cambiar_contrasenha/:token">
              <RecoveryPasswordToken></RecoveryPasswordToken>
            </Route>

            <Route exact path="/new_user/:token">
              <NewUserExternal />
            </Route>

            <Route exact path="/">
              <Redirect to="/login">Test</Redirect>
            </Route>

            <Route exact path="/recuperar-contrasenha">
              <RecoveryPassword></RecoveryPassword>
            </Route>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/mapa/:coords"
            >
              <MainMap height="100vh" marker={false}  main={true}/>
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/mapa"
            >
              <MainMap height="100vh" marker={false} />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(user, [1, 2, 3, 4])}
              exact
              path="/editar-wayfinding/:wf_id"
            >
              <EditWayfinding />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(user, [1, 2, 3, 4])}
              exact
              path="/lista-incidencias/:wf_id"
            >
              <IncidenceList />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(user, [1, 2, 3])}
              exact
              path="/lista-wayfindings"
            >
              <WayfindingList />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(user, [1, 2, 3, 4])}
              exact
              path="/nuevo-wayfinding"
            >
              <NewWayfinding />
            </PrivateRoute>

            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(user, [1])}
              exact
              path="/colaboradores"
            >
              <Partners />
            </PrivateRoute>

            {/* <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user,
                [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
              )}
              exact
              path="/estadisticas"
            >
              <Statistics />
            </PrivateRoute> */}
          </Switch>
        </div>
      </div>
    </>
  );
};

const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/mapa" />;
    }
  } else {
    return <Redirect to="/login" />;
  }
};
