import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  esES,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import "./TableNormal.css";

function TableNormal(props: any) {
  const {
    rows,
    columns,
    setEdit,
    edit,
    singleIcon,
    onCellClick,
    addIcon,
    handleAdd,
    exportIcon,
  } = props;
  const [columsCustom, setColumsCustom] = useState<GridColDef[]>([]);
  const [columsRows, setRowsCustom] = useState([]);

  useEffect(() => {
    processData();
  }, []);

  const processData = () => {
    setRowsCustom(rows);

    let array: GridColDef[] = [];

    let columsAux = JSON.parse(JSON.stringify(columns));

    columsAux.map((row: GridColDef, i: number) => {
      row.editable = false;

      if (columns[i].renderCell) {
        row.renderCell = columns[i].renderCell;
      }

      if (columns[i].renderEditCell) {
        row.renderEditCell = columns[i].renderEditCell;
      }

      array.push(row);
    });

    array.sort((a, b) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });

    setColumsCustom(array);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const CustomToolbar = () => {
    return (
      <>
        {edit ? (
          <GridToolbarContainer
            style={{ display: "flex", justifyContent: "end" }}
          >
            <h1 className='titleTableNormal'>{props.title}</h1>

            {singleIcon && addIcon ? (
              <IconButton onClick={handleAdd}>
                <AddCircleOutlineIcon />
              </IconButton>
            ) : singleIcon ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleEdit}>
                <DeleteIcon />
              </IconButton>
            )}
          </GridToolbarContainer>
        ) : (
          <GridToolbarContainer
            style={{ display: "flex", justifyContent: "end" }}
          >
            <h1 className='titleTableNormal'>{props.title}</h1>
            {exportIcon ? (
              <GridToolbarExport
                csvOptions={{
                  utf8WithBom: true,
                  fields: [
                    "created_at",
                    "user",
                    "updated_at",
                    "last_mod_user",
                    "promotion",
                    "format",
                    "territorial_name",
                    "monthly_price",
                    "incidences",
                    "ph_number",
                    "circlet",
                  ],
                }}
                printOptions={{
                  fields: [
                    "created_at",
                    "user",
                    "updated_at",
                    "last_mod_user",
                    "promotion",
                    "format",
                    "territorial_name",
                    "monthly_price",
                    "incidences",
                    "ph_number",
                    "circlet",
                  ],
                }}
              />
            ) : (
              <></>
            )}
          </GridToolbarContainer>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        height: "auto",
        background: "white",
        width: "80%",
        margin: "auto",
      }}
    >
      <DataGrid
        {...props}
        onCellClick={(event) => props.onCellClick(event)}
        localeText={{
          toolbarExport: "",
          toolbarExportCSV: "Descargar CSV",
          toolbarExportPrint: "Imprimir tabla",
        }}
        // localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: CustomToolbar }}
        rows={columsRows}
        columns={columsCustom}
        disableSelectionOnClick={false}
        editMode='row'
        getRowId={props.getRowId}
        autoHeight={true}
      ></DataGrid>
    </div>
  );
}

export default TableNormal;
