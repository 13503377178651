import axios from "axios";
import { toast } from "react-toastify";

export const getAllDelegations = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND_DW + "/del/all",
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getDelegationById = (id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/territorial_delegation/" + id,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const getUserDT = (id: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/territorial_delegation/userDT?id=" +
        id,
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
export const getFreeDT = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .get(process.env.REACT_APP_API_BACKEND + "/territorial_delegation/unused", config)
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
export const updateUserDT = (data: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/territorial_delegation/asigDT",
      data, 
      config
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};
