import axios from "axios";

export const getFilterOptions = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/filter/options", 
    {
      format: params.format,
      dt: params.dt,
      proms: params.proms,
    },
    config);
};

export const applyFilterWF = (params: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/filter/options/apply",
    {
      format: params.format,
      dt: params.dt,
      proms: params.proms,
    },
    config);
};
